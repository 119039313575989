import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const User = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleUserNavigate = () => {
    navigate("/home");
  };
  const handleUploadNavigate = () => {
    navigate("/Upload");
  };
  const handleCreateProfileNavigate = () => {
    navigate("/Create");
  };
  const handleCreateSchemNavigate = () => {
    navigate("/SchemesAdmin");
  };

  return (
    <>
      {location.pathname !== "/" && (
        <div className="w-[40vh] h-[120vh] bg-gray-500 flex flex-col">
          <div
            className="px-6 py-3 text-white cursor-pointer  hover:bg-gray-400"
            onClick={handleUserNavigate}
          >
            <div className="p-1">User List</div>
          </div>
          <div
            className="px-6 py-3 cursor-pointer text-white  hover:bg-gray-400"
            onClick={handleUploadNavigate}
          >
            <div className="p-1">Upload Files</div>
          </div>
          <div
            className="px-6 py-3 cursor-pointer text-white  hover:bg-gray-400"
            onClick={handleCreateProfileNavigate}
          >
            <div>Create Profile</div>
          </div>
          <div
            className="px-6 py-3 cursor-pointer text-white  hover:bg-gray-400"
            onClick={handleCreateSchemNavigate}
          >
            <div>Create Schemes</div>
          </div>
          <div
            className="px-6 py-3 cursor-pointer text-white  hover:bg-gray-400"
            // onClick={handleCreateSchemNavigate}
            onClick={() => {
              localStorage.clear();
              navigate("/");
            }}
          >
            <div>Logout</div>
          </div>
        </div>
      )}
    </>
  );
};

export default User;
