 function validateField(value, fieldName) {
  if (!value || value === "") {
    throw new Error("This field is required: " + fieldName);
  }
}

// function to validate number or interger
  function validateNumber(value) {
  if (!value || value === "") {
    throw new Error("This field is required");
  }
  if (typeof value !== "number") {
    throw new Error("Please enter a valid number");
  }
}


 function validateEmail(email) {
  if (!email || email === "") {
    throw new Error("Email is required");
  }
  if (!email.includes("@") || !email.includes(".")) {
    throw new Error("Please enter a valid email");
  }
}

 function validatePhone(phone) {
  if (!phone || phone === "") {
    throw new Error("Phone number is required");
  }
  if (phone.length !== 10) {
    throw new Error("Please enter a valid phone number");
  }
}

 function validateName(name, fieldName) {
  if (!name || name === "") {
    throw new Error("Name is required: " + fieldName);
  }
}

 function validateFile(file) {
  if (!file) {
    throw new Error("Please upload a file");
  }
}

 function validateArray(array) {
  //check if the array is an array
  if (!Array.isArray(array) || !array || array.length === 0) {
    throw new Error("Please provide an array");
  }
}

//validation for object
 function validateObject(object) {
  console.log("object", object, typeof typeof object);
  if (!object || typeof object !== "object") {
    throw new Error("Please provide an object");
  }
}



 function validateTimestamp(timestamp) {
  // Check if the timestamp is a number
  if (typeof timestamp !== 'number') {
    throw new Error("Timestamp must be a number");
  }

  // Check if the timestamp is a valid date
  const date = new Date(timestamp);
  if (isNaN(date.getTime())) {
    throw new Error("Invalid timestamp");
  }

  // Optionally, you can add more checks, such as ensuring the timestamp is within a reasonable range
  const minDate = new Date('1970-01-01').getTime(); // Unix epoch start
  const maxDate = new Date('2100-01-01').getTime(); // Arbitrary future date

  if (timestamp < minDate || timestamp > maxDate) {
    throw new Error("Timestamp is out of valid range");
  }
}

//function to validate a float value, it can either be 1, 1.25, 1.5,  2
 function validateMultiplier(value) {
  
  if (!value || value === "") {
    throw new Error("This field is required");
  }
  console.log(typeof value, value, value == 1)
  if (typeof value !== "number") {
    throw new Error("Please enter a valid number");
  }
  if (value != 1 && value != 1.25 && value != 1.5 && value != 2) {
    throw new Error("Valid multipliers are 1, 1.25, 1.5 and 2");
  }
}

// function to validate boolean value
function validateBoolean(value) {
  if (typeof value !== "boolean") {
    throw new Error("Please enter a valid boolean value");
  }
}

export {
  validateField,
  validateEmail,
  validatePhone,
  validateName,
  validateFile,
  validateArray,
  validateObject,
  validateTimestamp,
  validateMultiplier,
  validateBoolean,
  validateNumber
}