import React, { useEffect, useState } from "react";
import "../App.css";
import "./User";
import {
  getTopRewardsAdminSorted,
  SortKeys,
  getAllPdfDownloadRequests,
  getAllContactUsData,
  createNewReward,
  RewardsCompletionState,
  signupNewAdminUser,
  loginAdminUser,
  claimReward,
  getAllClaimedRewards,
  getOrdersList,
  updateBillPaidStatus,
  deleteBill,
  getAllRewardsOfOneUser,
  deleteOrder,
  getDeletedOrders,
  getAllRewardsCreatedByAdmin,
  deleteARewardById,

} from "../firebase/firebase_admin";
import { Link } from "react-router-dom";

function Table({ handleClick }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFiles((prevFiles) => [...prevFiles, event.target.files[0]]);
  };

  const [data, setData] = useState([]);
  const [contactUsUserData, setContactUsUserData] = useState([]);
  const [scheduleMeetingData, setScheduleMeetingData] = useState([]);
  const [filters, setFilters] = useState({
    sort: SortKeys.PROGRESS,
    completionState: RewardsCompletionState.ABOUT_TO_COMPLETE,
    numberOfRewards: 1,
  });
  // Pagination states for the first table (Rewards Table)
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Adjust number of items per page here

  // Pagination states for the second table (PDF Download Requests)
  const [contactCurrentPage, setContactCurrentPage] = useState(1);
  const [contactItemsPerPage] = useState(5);

  // Pagination states for the third table (Schedule Meeting Requests)
  const [scheduleCurrentPage, setScheduleCurrentPage] = useState(1);
  const [scheduleItemsPerPage] = useState(5);

  const handleButtonClick = async () => {
    console.log("Button clicked!");
    // getDeletedOrders();
  //  await getAllRewardsCreatedByAdmin();
   await deleteARewardById("-O67atTaCZ51kkI1N1ec");
  //  await getAllRewards("x68Teg68AOclz0kPWBrWuMh2cB33");
  //  await deleteBill("Dddj5t14akYgdk2ALkFiVLUyEA13", "-O6jSM1pkQuRI29TT7Yo", "Maja nahi aya");
    //  await signupNewAdminUser("madhavflutter@gmail.com", "Test@123");
    // await loginAdminUser("madhavnodejs@gmail.com", "Test@123");
    // await deleteOrder('3OeEoj2CrgOsEwqUglSbYzHkFSN2', '-NvkM6QYYOVYzqp37VVx', "Maja nahi aya");
    // await getAllClaimedRewards();
    //imageFiles: any, title: any, subtitle: any, pointsRequired: any, schemeStartDate: any, schemeEndDate: any, giftName: any
    // await createNewReward(selectedFiles, "title2", "subtitle2", 100, Date.now(), Date.now(), "giftName2");
    // Add your function logic curre
    // await getAllPdfDownloadRequests()
    // await getTopRewardsAdminSorted(SortKeys.PROGRESS, RewardsCompletionState.CLAIMED, 1);
    // await getAllRewards('MuhcpNvDyEa3XCPTcLrbvvXWk3H3', '-O60uhwXuD3tcrlE5wyN');
    // getAssuranceAgreementUrl();
    // getContactUsVerifyEmailUrl();
    // getVerifyPdfDownloadRequestEmailUrl();

    // const pdfUrl = await get5ImportantPointsUrl();
    // console.log(pdfUrl);
    // const banerUrl = await getBanerAdUrl();
    // console.log(banerUrl);
    // const assuranceUrl = await getAssuranceAgreementUrl();
    // console.log(assuranceUrl);
    // const contactUsVerifyEmail = await getContactUsVerifyEmailUrl();
    // console.log(contactUsVerifyEmail);
    // const verifyPdfDownloadRequestEmail = await getVerifyPdfDownloadRequestEmailUrl();
    // console.log(verifyPdfDownloadRequestEmail);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const output = await getTopRewardsAdminSorted(
          filters.sort,
          filters.completionState,
          filters.numberOfRewards
        );
        setData(output);
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, [filters]);

  useEffect(() => {
    const fetchContactUsData = async () => {
      try {
        const output = await getAllPdfDownloadRequests();
        setContactUsUserData(output);
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchContactUsData();
  }, []);

  useEffect(() => {
    const fetchScheduleMeetingData = async () => {
      try {
        const output = await getAllContactUsData();
        setScheduleMeetingData(output);
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchScheduleMeetingData();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const timeOptions = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedTime = date.toLocaleTimeString("en-US", timeOptions);
    const dateOptions = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-GB", dateOptions);
    return `${formattedTime} ${formattedDate}`;
  };

  // Pagination logic for the first table (Rewards Table)
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Pagination logic for the second table (PDF Download Requests)
  const contactIndexOfLastItem = contactCurrentPage * contactItemsPerPage;
  const contactIndexOfFirstItem = contactIndexOfLastItem - contactItemsPerPage;
  const currentContactItems = contactUsUserData
    .slice()
    .reverse()
    .slice(contactIndexOfFirstItem, contactIndexOfLastItem);

  const contactPaginate = (pageNumber) => setContactCurrentPage(pageNumber);

  // Pagination logic for the third table (Schedule Meeting Requests)
  const scheduleIndexOfLastItem = scheduleCurrentPage * scheduleItemsPerPage;
  const scheduleIndexOfFirstItem =
    scheduleIndexOfLastItem - scheduleItemsPerPage;
  const currentScheduleItems = scheduleMeetingData
    .slice()
    .reverse()
    .slice(scheduleIndexOfFirstItem, scheduleIndexOfLastItem);

  const schedulePaginate = (pageNumber) => setScheduleCurrentPage(pageNumber);

  const renderPagination = (
    totalItems,
    itemsPerPage,
    paginate,
    currentPage
  ) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
    console.log(currentItems);
    return (
      <div className="pagination">
        {pageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => paginate(number)}
            className={`px-2 py-1 ${
              currentPage === number ? "bg-blue-500 text-white" : "bg-gray-200"
            }`}
          >
            {number}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="w-[100%] h-[100vh] bg-gray-200">
      <div className="px-10 py-3">
        <div className="flex justify-between w-100 bg-dark">
          <div className="d-flex flex-column">
            <label>Sort</label>
            <select
              onChange={(e) => setFilters({ ...filters, sort: e.target.value })}
            >
              <option value={SortKeys.PROGRESS}>{SortKeys.PROGRESS}</option>
              <option value={SortKeys.DIFFERENCE}>{SortKeys.DIFFERENCE}</option>
              <option value={SortKeys.USER_ID}>{SortKeys.USER_ID}</option>
              <option value={SortKeys.DATE_JOINED}>
                {SortKeys.DATE_JOINED}
              </option>
            </select>
          </div>
          <div className="d-flex flex-column">
            <label>Completion status</label>
            <select
              onChange={(e) =>
                setFilters({ ...filters, completionState: e.target.value })
              }
            >
              <option value={RewardsCompletionState.ABOUT_TO_COMPLETE}>
                {RewardsCompletionState.ABOUT_TO_COMPLETE}
              </option>
              <option value={RewardsCompletionState.ALL}>
                {RewardsCompletionState.ALL}
              </option>
              <option value={RewardsCompletionState.CLAIMED}>
                {RewardsCompletionState.CLAIMED}
              </option>
              {/* <option value={RewardsCompletionState.DISPACTCHED}>
                {RewardsCompletionState.DISPACTCHED}
              </option> */}
            </select>
          </div>
          <div className="d-flex flex-column">
            <label>No. of rewards</label>
            <select
              onChange={(e) =>
                setFilters({ ...filters, numberOfRewards: e.target.value })
              }
            >
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </div>
        </div>
        <div className="flex flex-col justify-center items-center">
          <strong>Rewards Table</strong>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Image</th>
                <th>Email</th>
                <th>Hospital</th>
                <th>Level</th>
                <th>Points</th>
                <th>Reward Name</th>
                <th>Points Required</th>
                <th>Claimed Status</th>
                <th>Progress</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((i, index) => (
                <tr key={index}>
                  <td>
                    <Link
                      onClick={() => {
                        handleClick(i);
                      }}
                      to="/OneUserView"
                    >
                      {i.name}
                    </Link>
                  </td>
                  <td>
                    <img
                      src={i.profilePhoto}
                      alt=""
                      className="h-[7vh] w-[7vh]"
                    ></img>
                  </td>
                  <td>{i.email}</td>
                  <td>{i.hospitalName}</td>
                  <td>{i.currentLevel}</td>
                  <td>{i.currentPoints}</td>
                  <td>{i.rewardGiftName}</td>
                  <td>{i.rewardPointsRequired}</td>
                  <td>{i.rewardClaimed}</td>
                  <td>{i.progressInCurrentReward}</td>
                  {/* <td>{i.rewardId}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination for first table */}
          {renderPagination(data.length, itemsPerPage, paginate, currentPage)}
        </div>

        <div className="mt-8">
          <strong>PDF Download Requests</strong>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Request Time</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentContactItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{formatDate(item.request_time)}</td>
                  <td>{item.verified ? "Verified" : "Not Verified"}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination for second table */}
          {renderPagination(
            contactUsUserData.length,
            contactItemsPerPage,
            contactPaginate,
            contactCurrentPage
          )}
        </div>

        <div className="mt-8">
          <strong>Schedule Meeting Requests</strong>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Request Time</th>
                <th>Hospital</th>
              </tr>
            </thead>
            <tbody>
              {currentScheduleItems.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.phone}</td>
                  <td>{item.email}</td>
                  <td>{formatDate(item.timestamp)}</td>
                  <td>{item.hospital}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Pagination for third table */}
          {renderPagination(
            scheduleMeetingData.length,
            scheduleItemsPerPage,
            schedulePaginate,
            scheduleCurrentPage
          )}
        </div>
      </div>
      <button
        onClick={handleButtonClick}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
      >
        Click me For Testing Functions
      </button>
      <input type="file" onChange={handleFileChange} />
    </div>
  );
}

export default Table;
