import {
  ContactUsModel,
  DeletedOrderModel,
  PdfDownloadRequestModel,
  RewardModel,
  UserDataModel,
  UserModel,
  UserRewardModel,
} from "./models/user_model";
import { signup_error, login_error } from "./utils/error_map";
// import database from "firebase/database";
import {
  createDataInFirebaseRTDB,
  generateNewKey,
  getData,
  readDataInOrder,
  readDataWithWhereValueIsLessThan,
  removeUserData,
  transactionUniversal,
  updateSingleNode,
} from "./services/rtdb_operations";
import {
  loginUserFirebaseAuth,
  signupUserFirebaseAuth,
} from "./services/firebase_auth";
import {
  assuranceAgreementPath,
  assuranceAgreementpdfUrlPath,
  banerAdImagePath,
  banerAdUrl,
  claimedRewardsPath,
  contactUsPath,
  contactUsVerifyEmailHttpEndPointUrl,
  deletedOrdersPath,
  fiveImportantPointsPath,
  fiveImportantPointsUrlPath,
  getAdminNodePath,
  getClaimedRewardPath,
  getClaimedRewardUserIdPath,
  getCurrentPointsPath,
  getDeletedOrderIdPath,
  getNextLevelPath,
  getOrderIdPath,
  getOrdersPath,
  getRewardIdPath,
  getTotalPointsPath,
  getUserNodePath,
  getUserPointsPath,
  levelsPath,
  pdfDownloadRequestsPath,
  rewardsPath,
  userPointsPath,
  usersPath,
  verifyPdfDownloadRequestEmailHttpEndPointUrl,
} from "./utils/db_paths";
import {
  uploadOneFile,
  uploadMultipleFiles,
} from "./services/firebase_storage";
import {
  validateArray,
  validateBoolean,
  validateEmail,
  validateField,
  validateFile,
  validateMultiplier,
  validateNumber,
  validateObject,
  validatePhone,
  validateTimestamp,
} from "./utils/validators";
import { pointsCalculator } from "./utils/business_logic";

// **Done
async function signupNewUserAdmin(
  profilePhotoFile,
  personalEmail,
  password,
  personalName,
  peronalPhone,
  hospitalName,
  hospitalAddress1,
  hospitalAddress2,
  hospitalPhone,
  authenticationDoc
) {
  validateFile(profilePhotoFile);
  validateEmail(personalEmail);
  validateField(password, "password");
  validateField(personalName, "personalName");
  validatePhone(peronalPhone);
  validateField(hospitalName, "hospitalName");
  validateField(hospitalAddress1, "hospitalAddress1");
  validateField(hospitalAddress2, "hospitalAddress2");
  validatePhone(hospitalPhone);
  validateFile(authenticationDoc);

  try {
    let profilePhotoURL = null;
    let userId = null;
    let authenticationDocUrl = null;

    const userCredential = await signupUserFirebaseAuth(
      personalEmail,
      password
    );
    console.info("userCredentialinADMIN", userCredential);

    userId = userCredential.uid;
    console.info("userCredentialinADMIN user", userCredential.uid);

    //get levels from the database
    const levels = await getData(levelsPath);

    //if the user was successfully signup and there was no error then upload profilePhoto file and get the url

    if (userId) {
      console.log("userId available uploading photo");
      profilePhotoURL = await uploadOneFile(userId, profilePhotoFile);
      authenticationDocUrl = await uploadOneFile(userId, authenticationDoc);
      console.log("Profile photo uploaded successfully:", profilePhotoURL);
    } else {
      console.log("userId not available");
    }

    if (userId && profilePhotoURL) {
      const path = getUserNodePath(userId);

      const data = {
        userId: userId,
        profilePhoto: profilePhotoURL,
        name: personalName,
        email: personalEmail,
        phone: peronalPhone,
        hospitalName: hospitalName,
        hospitalAddress1: hospitalAddress1,
        hospitalAddress2: hospitalAddress2,
        hospitalPhone: hospitalPhone,
        authenticationDoc: authenticationDocUrl,
        dateJoined: new Date().toISOString(),
      };

      //Create a node under userPoints with the userId as the key and the value as the profileData Object
      //These are the fields under the userPoints node
      //currentLevel

      const userProfilePath = getUserPointsPath(userId);

      const profileData = {
        currentLevel: 0,
        currentPoints: 0,
        nextLevel: 1,
        progressInNextLevel: 0,
        pointsRequiredForNextLevel: levels[1],
        rewardsClaimed: 0,
        totalOrders: 0,
        totalPointsEarned: 0,
      };

      await createDataInFirebaseRTDB(path, data)
        .then(async () => {
          console.log("Signup user data written to the RTDB");
          await createDataInFirebaseRTDB(userProfilePath, profileData)
            .then(() => {
              console.log("User profile data written to the RTDB");
            })
            .catch(async (error) => {
              console.error("An error occurred:", error.message);

              //Rollback the transaction
              console.log("Rollback the new data added to users node");
              await removeUserData(path);
            });
        })
        .catch((error) => {
          console.error("An error occurred:", error.message);
        });
    }

    return userCredential;
  } catch (error) {
    throw new Error(error);
  }
}

// * Done ||
async function uploadBillsAndAddData(
  // ** to make input filed for
  hospitalUserId,
  billFiles,
  billAmount,
  pointsMultiplier // *default value
) {
  if (pointsMultiplier === undefined) {
    pointsMultiplier = 1;
  }

  console.log("hospitalUserId", hospitalUserId);
  console.log("billFiles", billFiles);
  console.log("billAmount", billAmount);
  console.log("pointsMultiplier", pointsMultiplier);

  validateField(hospitalUserId, "UserId");
  validateFile(billFiles);
  validateField(billAmount, "billAmount");
  validateMultiplier(parseFloat(pointsMultiplier));

  // Upload the bill files and get their URLs
  // const billFileURLs = await uploadMultipleFiles(hospitalUserId, billFiles);
  const billFileURLs = await uploadOneFile(hospitalUserId, billFiles);
  console.log("Bill files uploaded successfully:", billFileURLs);

  // Calculate the points
  const points = pointsCalculator(billAmount, pointsMultiplier);
  console.log("Points earned:", points);

  const key = generateNewKey();

  // Add data to the database
  const orderIdPath = getOrderIdPath(hospitalUserId, key);

  // Prepare the data
  const data = {
    billFileURLs: billFileURLs,
    billAmount: billAmount,
    pointsEarned: points,
    date: new Date().toISOString(),
    multipiler: pointsMultiplier,
    status: "order placed",
  };

  const levels = await getData(levelsPath);

  const userPointsPath = getUserPointsPath(hospitalUserId); //'/userPoints/dfsf';

  await createDataInFirebaseRTDB(orderIdPath, data)
    .then(async () => {
      console.log("Data added to the database successfully");
      await getData(userPointsPath);
      console.log("userPointsPath", points, levels, userPointsPath);
      await transactionUniversal(userPointsPath, (user) =>
        serverSideUpdates(user, points, levels)
      )
        .then(() => {
          console.log("Transaction completed successfully");
        })
        .catch(async (error) => {
          console.error("Transaction failed:", error.message);
          //Rollback the transaction
          console.log("Rollback the new data added to orders node");
          await removeUserData(orderIdPath);
        });
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
  // console.log("Data added to the database successfully");
}
// ** not for front-end
function serverSideUpdates(user, pointsEarned, levels) {
  // validateObject(user, "user");
  console.log("object", user, typeof typeof user);
  validateField(pointsEarned, "pointsEarned");
  validateField(levels, "levels");

  console.log("user", user, "pointsEarned", pointsEarned, "levels", levels);

  if (user) {
    user.totalOrders += 1;
    user.totalPointsEarned += pointsEarned;
    user.currentPoints += pointsEarned;

    updateUserLevels(user, levels);
  }
  console.log("return user", user);
  return user;
}

function updateUserLevels(user, levels) {
  for (let i = 0; i < levels.length; i++) {
    if (
      user.totalPointsEarned >= levels[i] &&
      user.totalPointsEarned < levels[i + 1]
    ) {
      user.currentLevel = i;
      user.nextLevel = i + 1;
      user.progressInNextLevel =
        (user.totalPointsEarned - levels[i]) / (levels[i + 1] - levels[i]);
      user.pointsRequiredForNextLevel = levels[i + 1] - user.totalPointsEarned;
      break;
    }
  }
}

// *** DONE
// schemeStartDate: Date.now(); // 1757423167545
// schemeEndDate: Date('2024-10-20'); // 1757423167545
async function createNewReward(
  // **MULTIPLE FILES
  imageFiles,
  title,
  subtitle,
  pointsRequired,
  schemeStartDate,
  schemeEndDate,
  giftName,
  unlocklevel
) {
  console.log("schemeStartDate: ", schemeStartDate);
  console.log(
    imageFiles,
    title,
    subtitle,
    pointsRequired,
    schemeStartDate,
    schemeEndDate,
    giftName,
    unlocklevel
  );

  validateFile(imageFiles);
  validateField(title, "title");
  validateField(subtitle, "subtitle");
  validateField(pointsRequired, "pointsRequired");
  validateTimestamp(schemeStartDate);
  validateTimestamp(schemeEndDate);
  validateField(giftName, "giftName");
  validateField(Number(unlocklevel), "unlocklevel");

  // Upload the image files and get their URLs
  //Instead of userId, we are using rewards, because we are storing the images in the rewards folder
  const imageURLs = await uploadMultipleFiles(rewardsPath, imageFiles);
  console.log("Image files uploaded successfully:", imageURLs);
  const rewardId = generateNewKey();

  const rewardData = {
    rewardId: rewardId,
    images: imageURLs,

    title: title,
    subtitle: subtitle,
    pointsRequired: pointsRequired,
    schemeStartDate: schemeStartDate,
    schemeEndDate: schemeEndDate,
    giftName: giftName,
    level: Number(unlocklevel),
  };

  console.log("Reward ID:", rewardId);

  const rewardIdPath = getRewardIdPath(rewardId);
  console.log("path", rewardIdPath);
  console.log("rewardData", rewardData);

  await createDataInFirebaseRTDB(rewardIdPath, rewardData)
    .then(() => {
      console.log("Reward data written to the RTDB");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });

  console.log("Reward data written to the RTDB");
  return {
    status: true,
  };
}

// *function to upload pdf for 5 important points ||done

async function uploadPdfFor5ImportantPoints(pdfFile) {
  validateFile(pdfFile);

  const pdfURL = await uploadOneFile("5-important-points", pdfFile);
  console.log("PDF file uploaded successfully:", pdfURL);

  const data = {
    pdfURL: pdfURL,
  };

  // const path = 'downloads/5_important_points';
  await createDataInFirebaseRTDB(fiveImportantPointsPath, data)
    .then(() => {
      console.log("PDF data written to the RTDB");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
  console.log("PDF data written to the RTDB");
}

// Create a function retrieve all the contact us data from the database
// and return it as an array of objects
async function getAllContactUsData() {
  const contactUsData = await getData(contactUsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  console.log("Contact us data:", contactUsData);

  let contactUsDataArray = Object.entries(contactUsData).map(([id, value]) => {
    return new ContactUsModel(id, value);
  });

  console.log("Contact us data array:", contactUsDataArray);

  return contactUsDataArray;
}

// export async function getAllContactUsData() {

//   // const path = 'contact_us';

//   const contactUsData = await getData(contactUsPath).catch((error) => {
//     console.error('An error occurred:', error.message);
//     return `An error occurred: ${error.message}`;
//   });

//   console.log('Contact us data:', contactUsData);

//   // return contactUsData;
// }
// *done
//function that takes an image as input and uploads it to the firebase storage and retrieves the download URL and writes that URL to the database
async function uploadBanerAdImageAndAddData(imageFile) {
  validateFile(imageFile);
  // Upload the image file and get its URL
  const imageURL = await uploadOneFile("baner_ad", imageFile);
  console.log("Image file uploaded successfully:", imageURL);

  // Prepare the data
  const data = {
    imageURL: imageURL,
  };

  // Add data to the database
  // const path = `downloads/baner_ad`;

  await createDataInFirebaseRTDB(banerAdUrl, data)
    .then(() => {
      console.log("Data added to the database successfully");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
}

//function to upload assuranace agreement
// *Done
async function uploadAssuranceAgreementPdf(pdfFile) {
  validateFile(pdfFile);

  const pdfURL = await uploadOneFile("assurance_agreement", pdfFile);
  console.log("PDF file uploaded successfully:", pdfURL);

  const data = {
    pdfURL: pdfURL,
  };

  await createDataInFirebaseRTDB(assuranceAgreementPath, data)
    .then(() => {
      console.log("PDF data written to the RTDB");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
  console.log("PDF data written to the RTDB");
}

//function to create an array of levels in db
async function createLevels(levels) {
  // if (!levels) {
  //   // *eg. [100,500,1000,1500,2000,2500]
  //   throw new Error("levels is undefined");
  // }
  validateArray(levels);
  // check if the levels is an array
  if (!Array.isArray(levels)) {
    throw new Error("levels is not an array");
  }

  // const path = 'levels';
  // add 0 in the beginning of the array to make the levels start from 0
  levels.unshift(0);

  await createDataInFirebaseRTDB(levelsPath, levels)
    .then(() => {
      console.log("Levels data written to the RTDB");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
  console.log("Levels data written to the RTDB");
}

// function to get all the levels from the database
async function getAllLevels() {
  const levels = await getData(levelsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  console.log("Levels data:", levels);

  return levels;
}

//this function get the list of all two top rewards of each user.
//Each top reward is the one in which the progress is less than 1, which means it is incomplete.
//The top rewards are sorted in descending order of progress.
//If the progress is more than 1, it means the reward is complete and should not be included in the top rewards.

//1. Get the list of all the points earned by each user.
//2. Get the list of all the rewards.
//3. For each user, calculate the progress of each reward.
//4. Filter the rewards based on the user's progress in it.
//5. Sort the rewards by progress in descending order.
//6. Pick the top 2 rewards by progress, by excluding the rewards with progress more than 1.
//7. Add thw top 2 rewards to the list of all users top rewards and return it.

export const SortKeys = {
  PROGRESS: "progress",
  DIFFERENCE: "difference",
  USER_ID: "userId",
  DATE_JOINED: "dateJoined",
};

export const RewardsCompletionState = {
  ABOUT_TO_COMPLETE: "aboutToComplete",
  ALL: "all",
  CLAIMED: "claimed",
  DISPACTCHED: "dispatched",
};

export const RewardClaimStatus = {
  CLAIMED_TRUE: "claimed",
  CLAIMED_FALSE: "unclaimed",
  DISPACTCHED: "dispatched",
};

//function to get the top 3 rewards of each user and sorted in descending order of progress

/**
 * Retrieves the top rewards sorted based on the specified sort key. Main functionality.
 *  Three types of sorting and filtering are supported:
 * 1. Sort the data
 * 2. Filter the data on the basis of completion state
 * 3. Limit the number of rewards to be returned
 *
 * @param {string} sortKey - Use the ENUM SortKeys. For Eg SortKeys.PROGRESS The key to sort the rewards by. Possible values are "progress", "difference", "userId", and "dateJoined".
 *   PROGRESS: "progress",
 *   DIFFERENCE: "difference",
 *   USER_ID: "userId",
 *   DATE_JOINED: "dateJoined",
 * 
 * @param {boolean} completionState - USe the ENUM RewardsCompletionState. For Eg RewardsCompletionState.COMPLETED. Indicates whether to include completed rewards in the result.
 *   COMPLETED: true,
  ABOUT_TO_COMPLETE: false,
  ALL: undefined,
 * @param {number} numberOfRewards - The number of top rewards to retrieve. Defaults to 1.
 * @returns {Array} - An array of rewards sorted based on the specified sort key.
 * @throws {Error} - If an invalid sort key is provided.
 */
async function getTopRewardsAdminSorted(
  sortKey,
  completionState,
  numberOfRewards = 1
) {
  validateField(sortKey, "sortKey");

  let allRewards = await getTopRewardsAdmin(completionState, numberOfRewards); // Assume this is your array of rewards

  // Define a sorting function
  let sortFunction;
  switch (sortKey) {
    case SortKeys.PROGRESS:
      sortFunction = (a, b) => b.progress - a.progress;
      break;
    case SortKeys.DIFFERENCE:
      sortFunction = (a, b) => a.difference - b.difference;
      break;
    case SortKeys.USER_ID:
      sortFunction = (a, b) => a.userId.localeCompare(b.userId);
      break;
    case SortKeys.DATE_JOINED:
      sortFunction = (a, b) => a.dateJoined.localeCompare(b.dateJoined);
      break;

    default:
      throw new Error(`Invalid sort key: ${sortKey}`);
  }

  // Sort the rewards using the sorting function
  allRewards.sort(sortFunction);
  console.log("allRewards ", allRewards);
  return allRewards;
}

async function getTopRewardsAdmin(completionState, numberOfRewards) {
  const userPoints = await getData(userPointsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  console.log(
    "madhav userPoints: ",
    userPointsPath,
    " ",
    userPoints.length,
    userPoints
  );

  const usersDetails = await getData(usersPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  // console.log("madhav usersDetails", usersDetails.length, usersDetails);

  const rewardList = await getData(rewardsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  const claimedRewards = await getData(claimedRewardsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  // console.log("madhav rewardList", rewardList.length, rewardList);
  // Converting object into an array
  let rewardsArray = Object.entries(rewardList).map(([id, value]) => ({
    id,
    ...value,
  }));

  // console.log("madhav rewardsArray", rewardsArray.length, rewardsArray);

  let allUsersTopRewards = [];

  for (let userId in userPoints) {
    console.log("madhav userId", userId, userPoints[userId], userPoints);
    let userRewards = [];
    rewardsArray.forEach((reward) => {
      let progress = userPoints[userId].currentPoints / reward.pointsRequired;
      let difference = reward.pointsRequired - userPoints[userId].currentPoints;
      // let userReward = new UserRewardModel(reward.activationPeriod, difference, reward.giftName, reward.id, reward.images, reward.pointsRequired, progress, reward.rewardId, reward.subtitle, reward.title, userId);
      // console.log("madhav userDetails", `${userId}`, usersDetails[userId]);

      const getClaimStatus = (claimedRewards) => {
        if (claimedRewards[userId]) {
          return claimedRewards[userId][reward.rewardId];
        } else {
          return {
            claimed: RewardClaimStatus.CLAIMED_FALSE,
            claimedAt: "",
          };
        }
      };
      let userReward = {
        ...reward,
        userId,
        progress,
        difference,
        ...usersDetails[userId],
        ...userPoints[userId],
        ...getClaimStatus(claimedRewards),
      }; // Create a new object

      console.log("madhav userReward", userReward);
      // if ( showCompleted === undefined) {
      //   userRewards.push(new UserRewardModel(userReward));
      // } else
      // if (completionState == RewardsCompletionState.ALL || progress < 1) {
      //   console.log("kumar", userReward);
      //   userRewards.push(new UserRewardModel(userReward));
      // }

      if (completionState === RewardsCompletionState.ALL) {
        // Push all userRewards
        console.log("kumar", userReward);
        userRewards.push(new UserRewardModel(userReward));
      } else if (
        completionState === RewardsCompletionState.ABOUT_TO_COMPLETE &&
        progress < 1
      ) {
        // Push only those where progress is less than 1
        console.log("kumar", userReward);
        userRewards.push(new UserRewardModel(userReward));
      } else if (
        completionState === RewardsCompletionState.CLAIMED &&
        userReward.claimed === RewardClaimStatus.CLAIMED_TRUE
      ) {
        // Push only those where claimed is equal to true
        console.log("kumar", userReward);
        userRewards.push(new UserRewardModel(userReward));
      } else if (
        completionState === RewardsCompletionState.DISPACTCHED &&
        userReward.claimed === RewardClaimStatus.DISPACTCHED
      ) {
        // Push only those where claimed is equal to true
        console.log("kumar", userReward);
        userRewards.push(new UserRewardModel(userReward));
      }

      console.log("madhav userRewards", userRewards);
    });

    // Sort the rewards by progress in descending order
    userRewards.sort((a, b) => b.progress - a.progress);

    console.log("madhav userRewards sorted", userRewards);

    // Get the top 3 rewards or number of rewards set by the user, but for claimed rewards, get all rewards, because then we need all the claimed rewards
    if (completionState !== RewardsCompletionState.CLAIMED) {
      userRewards = userRewards.slice(0, numberOfRewards);
    }

    console.log("madhav userRewards sliced", userRewards);

    // Push each userReward into the allUsersTopRewards array
    userRewards.forEach((userReward) => {
      // console.log("madhav", userReward);
      allUsersTopRewards.push(userReward);
    });
  }

  console.log("Top 3 rewards:", allUsersTopRewards);
  return allUsersTopRewards;
}

// async function getTopRewards(userPoints, rewardsArray, userId) {
//   console.log("userPoints2", userId, userPoints,);
//   let userRewards = [];
//   //  await rewardsArray.forEach(async (reward) => {
//   for (let reward in rewardsArray) {
//     console.log("userId2", userId, reward, userPoints[userId].currentPoints, rewardsArray[reward]);

//     // reward.progress = (userPoints[userId].currentPoints / reward.pointsRequired);
//     // reward.currentPoints = userPoints[userId].currentPoints;

//     // reward.userId = userId;
//     // userRewards.push(reward);

//     rewardsArray[reward].progress = (userPoints[userId].currentPoints / rewardsArray[reward].pointsRequired);
//     rewardsArray[reward].currentPoints = userPoints[userId].currentPoints;

//     rewardsArray[reward].userId = userId;
//     userRewards.push(rewardsArray[reward]);
//   };

//   // Sort the rewards by progress in descending order
//   userRewards.sort((a, b) => b.progress - a.progress);

//   // Get the top 3 rewards
//   // userRewards = userRewards.slice(0, 3);

//   console.log("madhav", userRewards);
//   return userRewards.slice(0, 3);
// }

// Function to get the list of all users and their details including from users node as well as userPoints node and return it as an array of a single object

// export async function getAllUsersData() {
//   const users = await getData(usersPath).catch((error) => {
//     console.error('An error occurred:', error.message);
//     return `An error occurred: ${error.message}`;
//   });

//   const userPoints = await getData(userPointsPath).catch((error) => {
//     console.error('An error occurred:', error.message);
//     return `An error occurred: ${error.message}`;
//   });

//   let allUsers = [];

//   for (let userId in users) {
//     let user = users[userId];
//     let userPoint = userPoints[userId];
//     allUsers.push(new UserDataModel(userId, user.email, user.hospitalAddress, user.hospitalName, user.hospitalPhone, user.name, user.phone, userPoint.currentLevel, userPoint.currentPoints, userPoint.nextLevel, userPoint.progressInNextLevel, userPoint.rewardsClaimed, userPoint.totalOrders, userPoint.totalPointsEarned));
//   }

//   return allUsers;
// }

//function to get the list of all pdfDownloadRequests users
// convert all those users into an array of objects and return it

async function getAllPdfDownloadRequests() {
  const pdfDownloadRequests = await getData(pdfDownloadRequestsPath).catch(
    (error) => {
      console.error("An error occurred:", error.message);
      return `An error occurred: ${error.message}`;
    }
  );

  let pdfDownloadRequestsArray = Object.entries(pdfDownloadRequests).map(
    ([id, value]) => {
      return new PdfDownloadRequestModel(id, value);
    }
  );

  console.log("PDF download requests:", pdfDownloadRequestsArray);
  return pdfDownloadRequestsArray;
}

async function get5ImportantPointsUrl() {
  const data = await getData(fiveImportantPointsUrlPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  return data;
}

async function getBanerAdUrl() {
  const data = await getData(banerAdImagePath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  return data;
}

async function getAssuranceAgreementUrl() {
  const data = await getData(assuranceAgreementpdfUrlPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  return data;
}

async function getContactUsVerifyEmailUrl() {
  const data = await getData(contactUsVerifyEmailHttpEndPointUrl).catch(
    (error) => {
      console.error("An error occurred:", error.message);
      return `An error occurred: ${error.message}`;
    }
  );

  return data;
}

async function getVerifyPdfDownloadRequestEmailUrl() {
  const data = await getData(
    verifyPdfDownloadRequestEmailHttpEndPointUrl
  ).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  return data;
}

// function to upload ContactUsVerifyEmailUrl
async function uploadContactUsVerifyEmailUrl(url) {
  validateField(url, "url");

  const data = {
    url: url,
  };

  await createDataInFirebaseRTDB(contactUsVerifyEmailHttpEndPointUrl, data)
    .then(() => {
      console.log("Data added to the database successfully");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
}

// **Done
async function signupNewAdminUser(personalEmail, password) {
  validateEmail(personalEmail);
  validateField(password, "password");

  try {
    let adminUserId = null;

    const userCredential = await signupUserFirebaseAuth(
      personalEmail,
      password
    );
    console.info("userCredentialinADMIN", userCredential);

    adminUserId = userCredential.uid;
    console.info("userCredentialinADMIN user", userCredential.uid);

    if (adminUserId) {
      const usersNodePath = getAdminNodePath(adminUserId);

      const userDetails = {
        userId: adminUserId,

        email: personalEmail,
        // password: password,
      };

      await createDataInFirebaseRTDB(usersNodePath, userDetails)
        .then(async () => {
          console.log("Signup admin data written to the RTDB");
        })
        .catch((error) => {
          console.error("An error occurred:", error.message);
        });
    }

    return userCredential;
  } catch (error) {
    throw new Error(error);
  }
}

async function loginAdminUser(email, password) {
  try {
    const userCredential = await loginUserFirebaseAuth(email, password);
    const adminUserId = userCredential.user.uid;
    console.info(
      "userCredentialinADMIN user",
      userCredential.user.uid,
      userCredential
    );

    //check if the user is an admin
    const adminNodePath = getAdminNodePath(adminUserId);
    const adminUser = await getData(adminNodePath);

    // console.log("adminUser", adminUser, typeof adminUser);

    if (adminUser === "No data available") {
      throw new Error("User is not an admin");
    } else {
      return new UserModel(userCredential);
    }
  } catch (error) {
    // return login_error[error.code] ?? error;

    const errorCode = error.code;
    throw new Error(login_error[errorCode] ?? error);
  }
}

//function to claim a reward
async function claimReward(userId, rewardId) {
  validateField(userId);
  validateField(rewardId);

  const userTotalPointsEarnedPath = getTotalPointsPath(userId);
  const userPointsPath = getUserPointsPath(userId);
  const rewardPath = getRewardIdPath(rewardId);
  const claimedRewardPath = getClaimedRewardPath(userId, rewardId);
  const orderPath = getOrdersPath(userId);

  const ordersObject = await getData(orderPath);
  console.log("ordersObject", ordersObject);

  // Convert ordersList to an array of values
  const ordersList = Object.values(ordersObject);
  console.log("ordersList", ordersList);

  // Get orders where the bills are paid
  const paidOrders = ordersList.filter((order) => order.billPaid == true);
  console.log("paidOrders", paidOrders);

  // Get the total points earned of all paid orders
  const totalPointsEarnedInPaidBills = paidOrders.reduce(
    (total, order) => total + order.pointsEarned,
    0
  );

  // Get the total points earned by the user
  let totalPointsEarned;
  await transactionUniversal(
    userTotalPointsEarnedPath,
    (currentTotalPoints) => {
      totalPointsEarned = currentTotalPoints;
      return currentTotalPoints;
    }
  );

  let reward;
  await transactionUniversal(rewardPath, (currentReward) => {
    reward = currentReward;
    return currentReward;
  });

  let transactionResult;
  await transactionUniversal(userPointsPath, (userPointsData) => {
    if (userPointsData && userPointsData.currentPoints !== undefined) {
      const currentPoints = userPointsData.currentPoints;
      const usedPoints = totalPointsEarned - currentPoints;
      const billPaidPointsAvailableToBeUsed =
        totalPointsEarnedInPaidBills - usedPoints;

      // If insufficient points for the reward
      if (reward.pointsRequired > currentPoints) {
        transactionResult = "Not enough points to claim this reward";
        return userPointsData; // Return data to leave it unchanged
      } else if (reward.pointsRequired > billPaidPointsAvailableToBeUsed) {
        // sufficient points but not enough points earned from paid bills minus used points
        transactionResult = "Bills not paid yet";
        return userPointsData; // Return data to leave it unchanged
      }
      transactionResult = "Reward claimed successfully";
      userPointsData.currentPoints = currentPoints - reward.pointsRequired; // Update currentPoints in the object
      userPointsData.rewardsClaimed += 1; // Increment rewardsClaimed in the object
    }

    return userPointsData; // Return updated object
  });

  if (
    transactionResult === "Not enough points to claim this reward" ||
    transactionResult === "Bills not paid yet"
  ) {
    console.log(transactionResult);
    return transactionResult;
  }

  const data = {
    claimed: RewardClaimStatus.CLAIMED_TRUE,
    claimedAt: new Date().toISOString(),
    rewardId: rewardId,
  };

  await createDataInFirebaseRTDB(claimedRewardPath, data);
  console.log(transactionResult);
  return transactionResult;
}

// function to get all the claimed rewards of all the users.
// Convert the object into an array of objects and return it
// reward name, user name, user points, claimed
async function getAllClaimedRewards() {
  const claimedRewards = await getData(claimedRewardsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });
  console.log("Claimed rewards:", claimedRewards);

  let claimedRewardsArray = Object.entries(claimedRewards).map(
    ([id, value]) => {
      return new UserRewardModel(id, value);
    }
  );

  console.log("Claimed rewards:", claimedRewardsArray);
  return claimedRewardsArray;
}

// Function to get the Orders List of a user
// Convert the object into an array of objects and return it with orderId as a key
async function getOrdersList(userId) {
  const orderPath = getOrdersPath(userId);

  const ordersObject = await getData(orderPath);
  console.log("ordersObject", ordersObject);

  // Convert ordersObject to an array of objects with orderId as a key
  const ordersList = Object.entries(ordersObject).map(([orderId, order]) => ({
    orderId,
    ...order,
  }));
  console.log("ordersList", ordersList);

  return ordersList;
}

// function to update the billPaid status of an order
// Update the billPaid status of an order to true/false
async function updateBillPaidStatus(userId, orderId, billPaid) {
  validateField(userId);
  validateField(orderId);
  validateField(billPaid);

  const orderPath = getOrderIdPath(userId, orderId);
  console.log(orderPath, billPaid);
  const data = {
    billPaid: billPaid,
  };
  let obj = {
    message: "",
    status: false,
  };
  await updateSingleNode(orderPath, data)
    .then(() => {
      console.log("Data added to the database successfully");
      obj = {
        message: "Data added to the database successfully",
        status: true,
      };
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
      obj = {
        message: error.message,
        status: false,
      };
    });
  return obj;
}

// function to update order status
async function updateOrderStatus(userId, orderId, status) {
  validateField(userId);
  validateField(orderId);
  validateField(status);

  const orderPath = getOrderIdPath(userId, orderId);

  const data = {
    status: status,
  };

  await updateSingleNode(orderPath, data)
    .then(() => {
      console.log("Data added to the database successfully");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
}

// function to update billPaid status of an order to dispatched
async function updateClaimedRewardStatus(userId, rewardId, status) {
  validateField(userId);
  validateField(rewardId);
  validateField(status);

  // check that status is only the ENUM RewardAndBillStatus
  if (
    status !== RewardClaimStatus.DISPACTCHED &&
    status !== RewardClaimStatus.CLAIMED_TRUE &&
    status !== RewardClaimStatus.CLAIMED_FALSE
  ) {
    throw new Error("Invalid status");
  }

  const claimedRewardPath = getClaimedRewardPath(userId, rewardId);

  const data = {
    status: status,
  };

  await updateSingleNode(claimedRewardPath, data)
    .then(() => {
      console.log("Data added to the database successfully");
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
}

//I want to create a function that will take the userid and orderId, and will get it's data under the orders/userId/orderId node and add it under deletedOrders/userId/orderId with delete reason and then delete that order under the orders node.
// function to delete a bill
// Delete the bill from the database
// and then minus the points from the user's currentPoints and totalPointsEarned in the userPoints node in the database using transaction
// and then delete the order from the orders node in the database
// and then delete the bill from the storage
// and then return the result
async function deleteBill(userId, orderId, deleteReason) {
  validateField(userId);
  validateField(orderId);
  validateField(deleteReason);

  const orderPath = getOrderIdPath(userId, orderId);
  const userPointsPath = getUserPointsPath(userId);
  const deletedOrderPath = getDeletedOrderIdPath(userId, orderId);

  const order = await getData(orderPath);
  const levels = await getData(levelsPath);
  console.log("order", order);

  const data = {
    ...order,
    deleteReason: deleteReason,
  };

  const points = order.pointsEarned;

  let transactionResult;
  await transactionUniversal(userPointsPath, (user) => {
    if (user) {
      if (user.currentPoints >= points) {
        user.currentPoints -= points;
        user.totalPointsEarned -= points;
        transactionResult = "Points deducted successfully";
        updateUserLevels(user, levels);
      } else {
        transactionResult = "Not enough points to deduct";
      }
    }
    return user;
  });

  if (transactionResult === "Not enough points to deduct") {
    console.log(transactionResult);
    return transactionResult;
  }

  await createDataInFirebaseRTDB(deletedOrderPath, data)
    .then(async () => {
      console.log("Data added to the database successfully");
      await removeUserData(orderPath);
    })
    .catch((error) => {
      console.error("An error occurred:", error.message);
    });
  console.log("Order deleted successfully");

  return transactionResult;
}

// // function to get the list of all the rewards
// async function getAllRewards(userId) {
//   // const path = 'rewards';
//   const currentPointsPath = getCurrentPointsPath(userId);

//   const rewards = await getData(rewardsPath).catch
//     ((error) => {
//       console.error('An error occurred:', error.message);
//       return `An error occurred: ${error.message}`;
//     });

//   console.log("rewards", rewards);

//   const claimedRewards = await getData(getClaimedRewardUserIdPath(userId)).catch((error) => {
//     console.error("An error occurred:", error.message);
//     return `An error occurred: ${error.message}`;
//   });

//   console.log("Claimed rewards:", claimedRewards);

//   const rewardsArray = Object.entries(rewards).map(([id, value]) => ({
//     id,
//     ...value,
//     ...claimedRewards[id],
//   }));

//   const userCurrentPoints = await getData(currentPointsPath).catch((error) => {
//     console.error("An error occurred:", error.message);
//     return `An error occurred: ${error.message}`;
//   });

//   rewardsArray.forEach(async (reward) => {
//     reward.progress = userCurrentPoints / reward.pointsRequired;
//   });
//   console.log("rewardsArray", rewardsArray);
//   return rewardsArray;
// }

// Function to get deleted orders of all users
// Convert the object into an array of objects and return it
async function getDeletedOrders() {
  const deletedOrders = await getData(deletedOrdersPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  let deletedOrdersArray = Object.entries(deletedOrders).map(
    ([userId, value]) => {
      return new DeletedOrderModel(userId, value);
    }
  );

  console.log("Deleted orders:", deletedOrdersArray);
  return deletedOrdersArray;
}

async function getAllRewardsOfOneUser(userId) {
  // const path = 'rewards';
  const currentPointsPath = getCurrentPointsPath(userId);
  const nextlevelpath = getNextLevelPath(userId);

  const nevelLevel = await getData(nextlevelpath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  console.log("nevelLevel", nevelLevel);
  const rewards = await readDataWithWhereValueIsLessThan(
    rewardsPath,
    nevelLevel,
    "level"
  ).catch((error) => {
    // console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  console.log("rewards", rewards);

  const claimedRewards = await getData(`${claimedRewardsPath}/${userId}`).catch(
    (error) => {
      console.error("An error occurred:", error.message);
      return `An error occurred: ${error.message}`;
    }
  );
  console.log("Claimed rewards:", claimedRewards);

  const rewardsArray = Object.entries(rewards).map(([id, value]) => ({
    id,
    ...value,
    ...claimedRewards[id],
  }));

  const userCurrentPoints = await getData(currentPointsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  rewardsArray.forEach(async (reward) => {
    reward.progress = userCurrentPoints / reward.pointsRequired;
  });
  console.log("rewardsArray", rewardsArray);
  return rewardsArray;
}

async function getAllRewardsCreatedByAdmin() {
  const allRewards = await getData(rewardsPath).catch((error) => {
    console.error("An error occurred:", error.message);
    return `An error occurred: ${error.message}`;
  });

  console.log("allRewards: ", allRewards);
  // Convert the allRewards object of objects into array of objects
  const arrayOfRewards = Object.entries(allRewards).map(([key, value]) => {
    return new RewardModel(key, value);
  });

  console.log("arrayOfRewards: ", arrayOfRewards);
  return arrayOfRewards;
}

// Function to delete a reward by its ID
async function deleteARewardById(rewardId) {
  const rewardPath = getRewardIdPath(rewardId);

  // Check if the reward exists
  const snapshot = await getData(rewardPath);

  if (!snapshot) {
    console.log(`Reward with ID ${rewardId} does not exist.`);
    return `Reward with ID ${rewardId} does not exist.`;
  }

  // Remove the reward
  await removeUserData(rewardPath);
  // console.log(`Reward with ID ${rewardId} has been deleted successfully.`);
  return `Reward with ID ${rewardId} has been deleted successfully.`;
}

export {
  // Post APIs
  // Signup and login
  signupNewUserAdmin,
  signupNewAdminUser,
  loginAdminUser,

  // Create
  claimReward,
  createNewReward,
  createLevels,

  // Read
  getAllClaimedRewards,
  getOrdersList,
  getAllRewardsOfOneUser,
  getAllLevels,
  getTopRewardsAdminSorted,
  getAllPdfDownloadRequests,
  getAllContactUsData,
  get5ImportantPointsUrl,
  getBanerAdUrl,
  getAssuranceAgreementUrl,
  getContactUsVerifyEmailUrl,
  getVerifyPdfDownloadRequestEmailUrl,
  getDeletedOrders,
  getAllRewardsCreatedByAdmin,

  // Update
  updateBillPaidStatus,
  updateOrderStatus,
  updateClaimedRewardStatus,

  // Delete
  deleteBill,
  deleteARewardById,

  // Upload files and add data
  uploadBillsAndAddData,
  uploadPdfFor5ImportantPoints,
  uploadBanerAdImageAndAddData,
  uploadAssuranceAgreementPdf,
};
