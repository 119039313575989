export const signup_error = {
    'auth/email-already-in-use': 'The email address is already in use by another account. Please use another email or login.',
    'auth/invalid-email': 'The email address is not valid. Please use a valid email address.',
    'auth/weak-password': 'The password is too weak. Please use a stronger password.'
    };

export const login_error = {
    'auth/user-not-found': 'The email address is not registered. Please register or use a valid email address.',
    'auth/wrong-password': 'The password is incorrect. Please use the correct password.',
    'auth/invalid-credential': 'The email address is not valid or password is incorrect. Please use a valid email address and correct password.'
    };    
