import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export async function uploadMultipleFiles(folderName, files) {

    if (!Array.isArray(files)) {
        throw new Error('files must be an array.');
      }
    
      files.forEach((file, index) => {
        if (!(file instanceof File)) {
          throw new Error(`files[${index}] must be an instance of File.`);
        }
      });
      
    // Map each file to a promise that resolves to its download URL
    const uploadPromises = files.map(file => uploadOneFile(folderName, file));

    // Wait for all uploads to complete and get their URLs
    const downloadURLs = await Promise.all(uploadPromises);
    return downloadURLs;
}

export async function uploadOneFile(folderName, file) {
    const storage = getStorage();
    const storageRef = ref(storage, `${folderName}/` + file.name);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
        uploadTask.on('state_changed', 
            (snapshot) => {
                // Handle the upload progress
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log(`Upload progress: ${progress}%`);
            }, 
            (error) => {
                // Handle unsuccessful uploads
                reject(error);
            }, 
            () => {
                // Handle successful uploads on complete
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    resolve(downloadURL);
                });
            }
        );
    });
}

