import React, { useEffect, useState } from "react";
import {
  createLevels,
  createNewReward,
  deleteARewardById,
  getAllLevels,
  getAllRewardsCreatedByAdmin,
} from "../firebase/firebase_admin";
import { toast } from "react-toastify";
import Loader from "./Loader";

const SchemesAdmin = () => {
  const [createSchemeData, setcreateSchemeData] = useState({
    imageFiles: null,
    title: "",
    subtitle: "",
    pointsRequired: 0,
    // activationPeriod: "",
    startDate: "",
    endDate: "",
    giftName: "",
    unlocklevel: "",
  });
  const [loading, setLoading] = useState(false);
  // ** for creating level
  const [rewardRefresh, setrewardRefresh] = useState(0);
  const [levels, setLevels] = useState("");
  const [getDataLevel, setGetDataLevel] = useState([]);
  const [allRewards, setAllRewards] = useState([]);
  console.log(allRewards);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const output = await getAllLevels();
        setGetDataLevel(output);
      } catch (error) {
        console.error("An error occurred:", error.message);
      }
    };

    fetchData();
  }, []);
  console.log(createSchemeData);

  const handleInputChange = (e) => {
    setLevels(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      // Convert the input string to an array of numbers
      const parsedLevels = levels.split(",").map(Number);

      // Call the createLevels function with the parsed levels
      await createLevels(parsedLevels);

      // Clear the input field after successful submission
      setLevels("");
      toast.success("Levels created successfully");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.error("Error creating levels:", error.message);
    }
  };
  // *****

  const handleCreateSchemeSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const getUnix = (date) => {
      const unixTimestamp = new Date(date).getTime();
      return unixTimestamp;
    };
    try {
      const imageFilesArray = [createSchemeData.imageFiles]; // Wrap the single file in an array

      const result = await createNewReward(
        imageFilesArray,
        createSchemeData.title,
        createSchemeData.subtitle,
        createSchemeData.pointsRequired,
        getUnix(createSchemeData.startDate),
        getUnix(createSchemeData.endDate),
        createSchemeData.giftName,
        createSchemeData.unlocklevel
      );
      if (result.status) {
        toast.success("Scheme created succesfully");
      }
      // Clear form data after successful submission
      setcreateSchemeData({
        imageFiles: null,
        title: "",
        subtitle: "",
        pointsRequired: 0,
        // activationPeriod: "",
        startDate: "",
        endDate: "",
        giftName: "",
        unlocklevel: "",
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
      console.error("Error creating reward:", error.message);
    }
  };

  useEffect(() => {
    const apiCall = async () => {
      const apiData = await getAllRewardsCreatedByAdmin();
      if (apiData) {
        setAllRewards(apiData);
      } else {
        toast.error("Something went wrong");
      }
    };

    apiCall();
  }, [rewardRefresh]);

  const deleteReward = async (id) => {
    setLoading(true);
    try {
      const result = await deleteARewardById(id);
      toast.warn(result);
      setrewardRefresh(rewardRefresh + 1);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // toast.error(error.message);
      console.error("Error creating reward:", error.message);
    }
  };

  return (
    <div className="bg-slate-200">
      <div className="grid grid-cols-12 p-4  w-100">
        <div className="col-span-6 border shadow-2xl rounded-xl border-gray-400 flex flex-col p-10">
          <div className="px-5 py-2  bg-red-500 text-white text-center font-sans font-medium text-xl my-2">
            Create Schemes
          </div>
          {loading ? <Loader /> : ""}
          <div className="flex flex-col">
            <div className="my-2">
              <label className="mx-2">Upload Image:</label>
              <input
                className="py-3"
                type="file"
                onChange={(e) =>
                  setcreateSchemeData({
                    ...createSchemeData,
                    imageFiles: e.target.files[0],
                  })
                }
              />
            </div>
            <div className="my-2">
              <label className="mx-2">Title:</label>
              <input
                className="py-2 rounded-xl p-2 "
                type="text"
                placeholder="Title"
                value={createSchemeData.title}
                onChange={(e) =>
                  setcreateSchemeData({
                    ...createSchemeData,
                    title: e.target.value,
                  })
                }
              />
            </div>
            <div className="my-2">
              <label className="mx-2">Subtitle :</label>
              <input
                className="py-2 rounded-xl p-2 "
                type="text"
                placeholder="Subtitle"
                value={createSchemeData.subtitle}
                onChange={(e) =>
                  setcreateSchemeData({
                    ...createSchemeData,
                    subtitle: e.target.value,
                  })
                }
              />
            </div>
            <div className="my-2">
              <label className="mx-2">Points Req :</label>
              <input
                className="py-2 rounded-xl p-2 "
                type="number"
                placeholder="Points Required"
                value={createSchemeData.pointsRequired}
                onChange={(e) =>
                  setcreateSchemeData({
                    ...createSchemeData,
                    pointsRequired: e.target.value,
                  })
                }
              />
            </div>
            <div className="my-2">
              <label className="mx-2">Unlock at level :</label>
              <input
                className="py-2 rounded-xl p-2 "
                type="number"
                placeholder="Points Required"
                value={createSchemeData.unlocklevel}
                onChange={(e) =>
                  setcreateSchemeData({
                    ...createSchemeData,
                    unlocklevel: e.target.value,
                  })
                }
              />
            </div>
            <div className="my-2">
              <label className="mx-2">activationPeriod:</label>
              <div className="d-flex ">
                <div className="d-flex flex-column">
                  <span>Start</span>{" "}
                  <input
                    type="date"
                    value={createSchemeData.startDate}
                    onChange={(e) =>
                      setcreateSchemeData({
                        ...createSchemeData,
                        startDate: e.target.value,
                      })
                    }
                  ></input>
                </div>
                <div className="d-flex flex-column">
                  <span>End</span>
                  <input
                    type="date"
                    value={createSchemeData.endDate}
                    onChange={(e) => {
                      setcreateSchemeData({
                        ...createSchemeData,
                        endDate: e.target.value,
                      });
                    }}
                  ></input>
                </div>
              </div>
              {/* <input
                className="py-2 rounded-xl p-2 "
                type="text"
                placeholder="Activation Period"
                value={createSchemeData.activationPeriod}
                onChange={(e) =>
                  setcreateSchemeData({
                    ...createSchemeData,
                    activationPeriod: e.target.value,
                  })
                }
              /> */}
            </div>
            <div className="my-2">
              <label className="mx-2">Gift Name :</label>
              <input
                className="py-2 rounded-xl p-2 "
                type="text"
                placeholder="Gift Name"
                value={createSchemeData.giftName}
                onChange={(e) =>
                  setcreateSchemeData({
                    ...createSchemeData,
                    giftName: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="my-2 mx-2">
            <button
              className="px-4 py-2 bg-blue-600 text-white rounded-xl"
              onClick={handleCreateSchemeSubmit}
            >
              submit
            </button>
          </div>
        </div>
        <div className="col-span-6 border mx-2 shadow-2xl rounded-xl border-gray-400 p-10">
          <div className="px-5 py-2 bg-red-500 text-white text-center font-sans font-medium text-xl my-2">
            Create Levels
          </div>
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Levels (comma-separated numbers):</label>
                <input
                  type="text"
                  value={levels}
                  onChange={handleInputChange}
                  placeholder="e.g., 100,500,1000,1500,2000,2500"
                />
              </div>

              <div className="my-2">
                <button
                  className="px-4 py-2 bg-blue-600 rounded-xl text-white"
                  type="submit"
                >
                  Create Levels
                </button>
              </div>
            </form>

            <div className="px-5 py-2 bg-red-500 text-white text-center font-sans font-medium text-xl my-2">
              Get Level
            </div>
            <div className="bg-red-200 p-10">
              {getDataLevel.map((item, index) => {
                return (
                  <div key={index} className="my-1">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="card">
            <table>
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Points Required</th>
                  <th>Title</th>
                  <th>Subtitle</th>
                  <th>Gift Name</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {allRewards.map((scheme, index) => (
                  <tr key={index}>
                    <td>{scheme.level}</td>
                    <td>{scheme.pointsRequired}</td>
                    <td>{scheme.title}</td>
                    <td>{scheme.subtitle}</td>
                    <td>{scheme.giftName}</td>
                    <td>
                      <button onClick={() => deleteReward(scheme.rewardId)}>
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchemesAdmin;
