import React, { useState } from "react";
import "./User";
import {
  uploadAssuranceAgreementPdf,
  uploadBanerAdImageAndAddData,
  uploadBillsAndAddData,
  uploadPdfFor5ImportantPoints,
} from "../firebase/firebase_admin";
// import User from './User';
const Upload = () => {
  const [banner, setBanner] = useState({
    bannerAdImg: null,
  });

  // const [assuranceAgreement, setAssuranceAgreement] = useState({
  //   assuranceAgreementImg: null,
  // });

  const [assuranceAgreement, setAssuranceAgreement] = useState({
    assuranceAgreementImg: null,
    pdfURL: null,
  });

  const [uploadPdfFivePoints, setUploadPdfFivePoints] = useState({
    uploadPdfFivePointsPdf: null,
  });
  const [uploadData, setUploadData] = useState({
    hospitalId: "",
    billFiles: null,
    billAmount: "",
    pointMultiplier: "",
  });
  const handleBillDataFile = (e) => {
    let file = e.target.files[0];
    setUploadData((prevData) => ({
      ...prevData,
      billFiles: file,
    }));
  };

  const handleuploadPdfFivePointsChange = (e) => {
    let file = e.target.files[0];
    setUploadPdfFivePoints({
      ...uploadPdfFivePoints,
      uploadPdfFivePointsPdf: file,
    });
  };
  const handleFileChange = (e) => {
    let file = e.target.files[0];
    setBanner({
      ...banner,
      bannerAdImg: file,
    });
  };

  const handleAssuranceAgreementFileChange = (e) => {
    let file = e.target.files[0];
    setAssuranceAgreement({
      ...assuranceAgreement,
      assuranceAgreementImg: file,
    });
  };

  const handleUploadFivePointPdfSubmit = async (e) => {
    e.preventDefault();
    try {
      let UploadFivePointPdf = await uploadPdfFor5ImportantPoints(
        uploadPdfFivePoints.uploadPdfFivePointsPdf
      );
      console.log("new UploadFivePointPdf", UploadFivePointPdf);
    } catch (error) {}
  };
  // *** for AssuranceAgreementFile

  const handleAssuranceAgreementFileSubmit = async (e) => {
    e.preventDefault();
    try {
      let assuranceAgreementdoc = await uploadAssuranceAgreementPdf(
        assuranceAgreement.assuranceAgreementImg
      );
      console.log("new assuranceAgreementdoc", assuranceAgreementdoc);
      setAssuranceAgreement(assuranceAgreementdoc.pdfURL);
    } catch (error) {
      console.error("Error uploading Assurance Agreement:", error);
    }
  };

  const handleBannerSubmit = async (e) => {
    e.preventDefault();
    try {
      let bannerData = await uploadBanerAdImageAndAddData(banner.bannerAdImg);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="grid grid-cols-12 w-100 bg-red-400 ">
        <div className="col-span-4 p-5 bg-yellow-100 w-100">
          <p className="font-bold">Banners:</p>
          <input type="file" onChange={handleFileChange}></input>

          <button
            className="px-4 py-2 border rounded-xl my-2 bg-blue-600 text-white"
            onClick={handleBannerSubmit}
          >
            submit
          </button>
        </div>
        <div className="col-span-4  p-5 bg-blue-100">
          <div className="font-bold">Upload Assurance Agreement:</div>
          <input
            type="file"
            // value={handleAssuranceAgreementFileChange}
            onChange={handleAssuranceAgreementFileChange}
          ></input>

          <button
            className="px-4 py-2 border rounded-xl my-2 bg-blue-600 text-white"
            onClick={handleAssuranceAgreementFileSubmit}
          >
            submit
          </button>
        </div>
        <div className="col-span-4  p-5 bg-green-100">
          <div className="font-bold">Upload PDF for five Points:</div>
          <input type="file" onChange={handleuploadPdfFivePointsChange}></input>

          <button
            className="px-4 py-2 border rounded-xl my-2 bg-blue-600 text-white"
            onClick={handleUploadFivePointPdfSubmit}
          >
            submit
          </button>
        </div>
      </div>
      {/* <div className="grid grid-cols-12">
        <div className="col-span-4 p-5 bg-red-100">
          <div className="font-bold">Upload Bill and Data:</div>
          <div>
            <input type="file" onChange={handleBillDataFile}></input>
          </div>

          <div className="my-2">
            <label className="">Hospital Id:</label>
            <input
              placeholder="Hospital ID"
              className="p-2 py-2 mx-1 rounded-xl"
              type="text"
              value={uploadData.hospitalId}
              onChange={(e) =>
                setUploadData({
                  ...uploadData,
                  hospitalId: e.target.value,
                })
              }
            />
          </div>
          <div className="my-2">
            <label>Bill Amount</label>
            <input
              placeholder="Bill Amount"
              className="py-2 p-2 mx-1 rounded-xl"
              type="number"
              value={uploadData.billAmount}
              onChange={(e) =>
                setUploadData({
                  ...uploadData,
                  billAmount: e.target.value,
                })
              }
            />
          </div>
          <div className="my-2">
            <label className="mx-1">Multiplier:</label>
            <input
              placeholder="Point Multiplier"
              className="py-2 p-2 mx-1 rounded-xl"
              type="number"
              value={uploadData.pointMultiplier}
              onChange={(e) =>
                setUploadData({
                  ...uploadData,
                  pointMultiplier: e.target.value,
                })
              }
            />
          </div>

          <button
            className="px-4 py-2 border rounded-xl my-2 bg-blue-600 text-white"
            onClick={handleuploadBillsAndAddDataSubmit}
          >
            submit
          </button>
        </div>
      </div> */}
    </div>
  );
};

export default Upload;
