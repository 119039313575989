//userCredential.user.uid, userCredential.user.email, userCredential.user.accessToken

export class UserModel {
  constructor(userCredential) {
    // this.uid = uid;
    // this.email = email;
    // this.accessToken = accessToken;
    this.uid = userCredential.user.uid;
    this.email = userCredential.user.email;
    this.accessToken = userCredential.user.accessToken;
  }
}

// export async function signupNewUserAdmin(profilePhotoFile, personalEmail, password, personalName, peronalPhone, hospitalName, hospitalAddress1, hospitalAddress2, hospitalPhone, authentication_doc) {
//     export async function uploadBillsAndAddData(hospitalUserId, billFiles, billAmount, pointsMultiplier) {
//     export async function createNewReward(
//     export async function uploadPdfFor5ImportantPoints(pdfFile) {
//     export async function getAllContactUsData() {*******************
//     export async function uploadBanerAdImageAndAddData(imageFile) {
//     export async function uploadAssuranceAgreementPdf(pdfFile) {
//     export async function createLevels(levels) {
//     export async function getAllLevels() {
//     export async function getTopRewardsAdminSorted(sortKey) {********
//     export async function getAllUsersData() { ************************
//     export async function getAllPdfDownloadRequests() { *************

// email
// :
// "madhavnodejs@gmail.com"
// messageId
// :
// "<467b34bc-95d0-6152-846d-911b1c67bf0d@gmail.com>"
// name
// :
// "madhav"
// request_time
// :
// "2024-09-04T21:29:57.164Z"
// success
// :
// true
// token
// :
// "2d9c5bc1421cc2e6f64b3e567429768ade080077"
// verified
// :
// false

//This is the return object from the getAllPdfDownloadRequests function
// Create a model for the pdf download requests

export class PdfDownloadRequestModel {
  constructor(id, requestDetails) {
    this.id = id;
    this.email = requestDetails.email;
    this.name = requestDetails.name;
    this.success = requestDetails.success || false;
    this.verified = requestDetails.verified || false;
    this.request_time = requestDetails.request_time || "";
  }
}

// export class UserDataModel {
//     constructor(userId, email, hospitalAddress, hospitalName, hospitalPhone, name, phone, currentLevel, currentPoints, nextLevel, progressInNextLevel, rewardsClaimed, totalOrders, totalPointsEarned) {
//         this.userId = userId;
//         this.email = email;
//         this.hospitalAddress = hospitalAddress;
//         this.hospitalName = hospitalName;
//         this.hospitalPhone = hospitalPhone;
//         this.name = name;
//         this.phone = phone;
//         this.currentLevel = currentLevel;
//         this.currentPoints = currentPoints;
//         this.nextLevel = nextLevel;
//         this.progressInNextLevel = progressInNextLevel;
//         this.rewardsClaimed = rewardsClaimed;
//         this.totalOrders = totalOrders;
//         this.totalPointsEarned = totalPointsEarned;
//     }
// }



export class ContactUsModel {
  constructor(id, contactDetails) {
    this.id = id;
    this.email = contactDetails.email;
    this.name = contactDetails.full_name;
    this.phone = contactDetails.phone_number;
    this.hospital = contactDetails.hospital_name;
    this.success = contactDetails.success || false; //default value
    this.messageId = contactDetails.messageId || "";
    this.verified = contactDetails.verified || false;
    this.timestamp = contactDetails.timestamp || "";
  }
}
//
// currentLevel
// :
// 1
// currentPoints
// :
// 0
// nextLevel
// :
// 2
// progressInNextLevel
// :
// 0
// rewardsClaimed
// :
// 0
// totalOrders
// :
// 4
// totalPointsEarned
// :
// 0
// 
// authenticationDoc
// :
// "https://firebasestorage.googleapis.com/v0/b/lifin-aidlife.appspot.com/o/3OeEoj2CrgOsEwqUglSbYzHkFSN2%2FTarunGupta22_02_2021-210630-135305%20(1).docx?alt=media&token=e0a1a37f-10e3-403f-9890-d3c4f96f7cb0"
// dateJoined
// :
// "2024-04-18T08:38:58.462Z"
// email
// :
// "sinchanjee@gmail.com"
// hospitalAddress1
// :
// "H.no 2/44; Rail vihar phase2 colony"
// hospitalAddress2
// :
// "Chargawan ; Little flower school"
// hospitalName
// :
// "Dholakpur"
// hospitalPhone
// :
// "8506971722"
// name
// :
// "Sinchan"
// phone
// :
// "8506971722"
// profilePhoto
// :
// "https://firebasestorage.googleapis.com/v0/b/lifin-aidlife.appspot.com/o/3OeEoj2CrgOsEwqUglSbYzHkFSN2%2Fjam-4521023_1280.jpg?alt=media&token=194f1781-c637-4ced-a2b7-ce62ff0be472"
// userId
// :
// "3OeEoj2CrgOsEwqUglSbYzHkFSN2"
// giftName
// :
// "giftName"
// images
// 0
// :
// "https://firebasestorage.googleapis.com/v0/b/lifin-aidlife.appspot.com/o/rewards%2FScreenshot%202024-09-05%20130233.png?alt=media&token=d0f6a2f7-c1c0-4a53-bf59-0abe3b52eb3b"
// 1
// :
// "https://firebasestorage.googleapis.com/v0/b/lifin-aidlife.appspot.com/o/rewards%2FScreenshot%20(117).png?alt=media&token=d111dacd-3cd9-4253-85de-9e7b9803be83"
// 2
// :
// "https://firebasestorage.googleapis.com/v0/b/lifin-aidlife.appspot.com/o/rewards%2FScreenshot%202024-08-29%20121823.png?alt=media&token=0c2786db-9070-412d-b9c1-f8a08c78ba3a"
// 3
// :
// "https://firebasestorage.googleapis.com/v0/b/lifin-aidlife.appspot.com/o/rewards%2FScreenshot%20(117).png?alt=media&token=d111dacd-3cd9-4253-85de-9e7b9803be83"
// pointsRequired
// :
// 100
// rewardId
// :
// "-O67atTaCZ51kkI1N1ec"
// schemeEndDate
// :
// 1725647521627
// schemeStartDate
// :
// 1725647521627
// subtitle
// :
// "subtitle"
// title
// :
// "title"



export class UserRewardModel {
  constructor(rewardDetails) {
    // this.schemeStartDate = rewardDetails.schemeStartDate;
    // this.schemeEndDate = rewardDetails.schemeEndDate;
    // this.difference = rewardDetails.difference;
    // this.giftName = rewardDetails.giftName;
    // this.currentLevel = rewardDetails.currentLevel;
    // this.rewardImages = rewardDetails.images;
    // this.pointsRequired = rewardDetails.pointsRequired;
    // this.progress = rewardDetails.progress;
    // this.rewardId = rewardDetails.id;
    // this.subtitle = rewardDetails.subtitle;
    // this.title = rewardDetails.title;
    // this.userId = rewardDetails.userId;
    // this.email = rewardDetails.email;
    // this.hospitalAddress1 = rewardDetails.hospitalAddress1;
    // this.hospitalAddress2 = rewardDetails.hospitalAddress2;
    // this.hospitalName = rewardDetails.hospitalName;
    // this.hospitalPhone = rewardDetails.hospitalPhone;
    // this.name = rewardDetails.name;
    // this.phone = rewardDetails.phone;
    // this.dateJoined = rewardDetails.dateJoined;
    // this.profilePhoto = rewardDetails.profilePhoto;
    // this.authenticationDoc = rewardDetails.authenticationDoc;

    this.currentLevel = rewardDetails.currentLevel;
    this.difference = rewardDetails.difference;
    this.currentPoints = rewardDetails.currentPoints;
    this.nextLevel = rewardDetails.nextLevel;
    this.progressInNextLevel = rewardDetails.progressInNextLevel;
    this.progressInCurrentReward = rewardDetails.progress;
    this.rewardsClaimed = rewardDetails.rewardsClaimed;
    this.totalOrders = rewardDetails.totalOrders;
    this.totalPointsEarned = rewardDetails.totalPointsEarned;
    this.authenticationDoc = rewardDetails.authenticationDoc;
    this.dateJoined = rewardDetails.dateJoined;
    this.email = rewardDetails.email;
    this.hospitalAddress1 = rewardDetails.hospitalAddress1;
    this.hospitalAddress2 = rewardDetails.hospitalAddress2;
    this.hospitalName = rewardDetails.hospitalName;
    this.hospitalPhone = rewardDetails.hospitalPhone;
    this.name = rewardDetails.name;
    this.phone = rewardDetails.phone;
    this.profilePhoto = rewardDetails.profilePhoto;
    this.userId = rewardDetails.userId;
    this.rewardGiftName = rewardDetails.giftName;
    this.rewardImages = rewardDetails.images;
    this.rewardPointsRequired = rewardDetails.pointsRequired;
    this.rewardId = rewardDetails.rewardId;
    this.schemeEndDate = rewardDetails.schemeEndDate;
    this.schemeStartDate = rewardDetails.schemeStartDate;
    this.rewardSubtitle = rewardDetails.subtitle;
    this.rewardTitle = rewardDetails.title;
    this.rewardClaimed = rewardDetails.claimed || false;
    this.rewardClaimedTime = rewardDetails.claimed ? rewardDetails.claimedAt : "";
  }
}


// DeletedOrdersModel
export class DeletedOrderModel {
  constructor(userId, value) {
    this.userId = userId;
    this.orderId = Object.keys(value)[0];
    const orderData = value[this.orderId];
    this.billAmount = orderData.billAmount;
    this.billFileURLs = orderData.billFileURLs;
    this.billPaid = orderData.billPaid;
    this.date = orderData.date;
    this.deleteReason = orderData.deleteReason;
    this.multiplier = orderData.multiplier;
    this.pointsEarned = orderData.pointsEarned;
    this.status = orderData.status;
  }
}

// RewardModel
export class RewardModel {
  constructor(key, rewardDetails) {
    this.rewardId = key;
    this.giftName = rewardDetails.giftName;
    this.images = rewardDetails.images;
    this.level = rewardDetails.level;
    this.pointsRequired = rewardDetails.pointsRequired;
    this.schemeEndDate = rewardDetails.schemeEndDate;
    this.schemeStartDate = rewardDetails.schemeStartDate;
    this.subtitle = rewardDetails.subtitle;
    this.title = rewardDetails.title;
  }
}