import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAdminUser } from "../firebase/firebase_admin";
import { toast } from "react-toastify";

const Login = ({ setIsAuthenticated }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the login function and await the result
      const res = await loginAdminUser(username, password);

      // Check if the response contains an access token
      if (res && res.accessToken) {
        // Save necessary data to localStorage
        localStorage.setItem("token", res.accessToken);
        localStorage.setItem("userId", res.uid);
        localStorage.setItem("email", res.email);

        // Set authentication state to true
        setIsAuthenticated(true);

        // Redirect to home page
        navigate("/home");
      } else {
        // If no access token is returned, display an error message
        toast.error("Invalid Credentials");
      }
    } catch (error) {
      // Handle possible errors like network issues or server errors
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized: Invalid credentials");
      } else if (error.response && error.response.status === 500) {
        toast.error("Server error: Please try again later");
      } else {
        toast.error("Invalid Credentials");
      }
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100 w-full">
      <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-gray-700 font-medium mb-2"
            >
              Username
            </label>
            <input
              type="text"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-700 font-medium mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300"
          >
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
