
const pointsCalculator = (billAmountInRupee, multipiler) => {
    // validateMultiplier(multipiler);
 // Points are given for every 5000 amount
 const pointsPer5000 = 100;
 const threshold = 5000;

 // Calculate the number of points
 const points = Math.floor(billAmountInRupee / threshold) * pointsPer5000;

 return points * multipiler;
};


export {
    pointsCalculator
}