// //function that takes uid and returns the db path as string.

//  function getUserNodePath(userId) {
//     return "users/" + userId;
// }

//  function getContactUsNodePath(key){
//     return `contact_us/${key}`;
// }

//  function getOrdersPath(userId){
//     return `orders/${userId}`;
// }

//  function getOrderIdPath(userId, key){
//     return `orders/${userId}/${key}`;
// }

//  function getUserPointsPath(userId){
//     return `userPoints/${userId}`
// }

//  function getCurrentPointsPath(userId){
//     return `userPoints/${userId}/currentPoints`;
// }

//  function getRewardIdPath(rewardId){
//     return `rewards/${rewardId}`;
// }

//  function getClaimedRewardPath(userId, rewardId){
//     return `claimedRewards/${userId}/${rewardId}`;
// }

 const usersPath = 'users';
 const adminPath = 'admin';
 const levelsPath = 'levels';
 const ordersPath = 'orders';
 const deletedOrdersPath = 'deletedOrders';
 const userPointsPath = 'userPoints';
 const rewardsPath = 'rewards';
 const contactUsPath = 'contact_us';
 const claimedRewardsPath = 'claimedRewards';
 const pdfDownloadRequestsPath = 'pdfDownloadRequests';

 const fiveImportantPointsPath = 'downloads/5_important_points'; 
 const fiveImportantPointsUrlPath = 'downloads/5_important_points/pdfURL';
 const banerAdUrl = 'downloads/banner_ad';
 const banerAdImagePath = 'downloads/banner_ad/imageURL';
 const assuranceAgreementPath = 'downloads/assurance_agreement';
 const assuranceAgreementpdfUrlPath = 'downloads/assurance_agreement/pdfURL';
 const contactUsVerifyEmailHttpEndPointUrl = 'downloads/verifyContactUsEmail/url';
 const verifyPdfDownloadRequestEmailHttpEndPointUrl = 'downloads/verifyNDownloadUrl/url';

 function getUserNodePath(userId) {
    return `${usersPath}/${userId}`;
}

 function getAdminNodePath(adminUserId) {
    return `${adminPath}/${adminUserId}`;
}

 function getContactUsNodePath(key){
    return `${contactUsPath}/${key}`;
}

 function getOrdersPath(userId){
    return `${ordersPath}/${userId}`;
}

 function getOrderIdPath(userId, orderId){
    return `${ordersPath}/${userId}/${orderId}`;                   
}

 function getUserPointsPath(userId){
    return `${userPointsPath}/${userId}`
}          

function getTotalPointsPath(userId){
    return `${userPointsPath}/${userId}/totalPointsEarned`
}      

 function getCurrentPointsPath(userId){
    return `${userPointsPath}/${userId}/currentPoints`;
}

 function getRewardIdPath(rewardId){
    return `${rewardsPath}/${rewardId}`;
}

 function getClaimedRewardPath(userId, rewardId){
    return `${claimedRewardsPath}/${userId}/${rewardId}`;
}

function getClaimedRewardUserIdPath(userId){
    return `${claimedRewardsPath}/${userId}`;
}

function getDeletedOrderIdPath(userId, orderId){
    return `${deletedOrdersPath}/${userId}/${orderId}`;
}

 function getNextLevelPath(userId) {
    return `${userPointsPath}/${userId}/nextLevel`;
  }

export {
    getUserNodePath,
    getAdminNodePath,
    getContactUsNodePath,
    getOrdersPath,
    getOrderIdPath,
    getUserPointsPath,
    getCurrentPointsPath,
    getRewardIdPath,
    getClaimedRewardPath,
    getTotalPointsPath,
    getClaimedRewardUserIdPath,
    getDeletedOrderIdPath,
    getNextLevelPath,
    usersPath,
    adminPath,
    levelsPath,
    ordersPath,
    userPointsPath,
    rewardsPath,
    contactUsPath,
    claimedRewardsPath,
    pdfDownloadRequestsPath,
    fiveImportantPointsPath,
    fiveImportantPointsUrlPath,
    banerAdUrl,
    banerAdImagePath,
    assuranceAgreementPath,
    assuranceAgreementpdfUrlPath,
    contactUsVerifyEmailHttpEndPointUrl,
    verifyPdfDownloadRequestEmailHttpEndPointUrl,
    deletedOrdersPath
}