// import {Card,Card2,Card5,Card6} from  './Create';
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteBill,
  getOrdersList,
  updateBillPaidStatus,
  uploadBillsAndAddData,
} from "../firebase/firebase_admin";
import { toast } from "react-toastify";
import Loader from "./Loader";

const OneUserView = ({ userDetails = "" }) => {
  // const navigate = useNavigate();
  // if (userDetails == "") {
  //   navigate("/home");
  // }

  // console.log(userDetails[0]?.userId);
  const [uploadData, setUploadData] = useState({
    hospitalId: "",
    billFiles: null,
    billAmount: "",
    pointMultiplier: "1",
  });
  const [myOrderRefresh, setMyOrderRefresh] = useState(0);
  const [myOrders, setMyOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleBillDataFile = (e) => {
    let file = e.target.files[0];
    setUploadData((prevData) => ({
      ...prevData,
      billFiles: file,
    }));
  };
  console.log(userDetails);
  const handleuploadBillsAndAddDataSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("hospitalUserId", userDetails[0]?.userId);
      formData.append("billFiles", uploadData.billFiles);
      formData.append("billAmount", uploadData.billAmount);
      formData.append("pointsMultiplier", uploadData.pointMultiplier);

      // Call the uploadBillsAndAddData function
      await uploadBillsAndAddData(
        userDetails[0]?.userId,
        uploadData.billFiles,
        uploadData.billAmount,
        uploadData.pointMultiplier
      );
      setMyOrderRefresh(myOrderRefresh + 1);
      // Clear form data after successful submission
      setUploadData({
        hospitalId: "",
        billFiles: null,
        billAmount: "",
        pointMultiplier: "",
      });
      toast.success("Bills uploaded successfully!");

      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      console.error("Error uploading bills:", error.message);
      setLoading(false);
    }
    console.log(userDetails[0].userId);
  };

  useEffect(() => {
    const getOrders = async () => {
      const result = await getOrdersList(userDetails[0].userId);
      // console.log(result);
      if (result) {
        setMyOrders(result);
      }
    };
    getOrders();
  }, [myOrderRefresh]);
  console.log(myOrders);
  const updateUserBillPaidStatus = async (orderId) => {
    setLoading(true);
    const result = await updateBillPaidStatus(
      userDetails[0].userId,
      orderId,
      true
    );
    if (result.status) {
      toast.success(result.message);
      setMyOrderRefresh(myOrderRefresh + 1);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(result.message);
    }
    console.log(result);
  };
  const handleDeleteOrder = async (orderId) => {
    setLoading(true);
    const result = await deleteBill(
      userDetails[0].userId,
      orderId,
      "Order Deleted"
    );
    if (result == "Points deducted successfully") {
      toast.success("Order Deleted Successfully");
      setMyOrderRefresh(myOrderRefresh + 1);
      setLoading(false);
    } else {
      toast.error("Not Deleted");
      setLoading(false);
    }
    console.log(result);
  };
  return (
    <div className="bg-yellow-200 h-[100vh] w-[100%] px-10">
      <div className="flex flex-col justify-center items-center">
        <div className="flex justify-end w-[100%]">
          <div style={{ display: "flex " }}>
            {/* <Card points={100} level="Beginner" />
            <Card2 reward_Claimed={100} reward_Unlock={10} /> */}
            {/* Add more Card components with different props as needed */}
          </div>
          <div className="border my-2 px-2 ">
            <Link to="/">X</Link>
          </div>
        </div>
        {loading ? <Loader /> : ""}
        <div className=" flex justify-between ">
          <div className="card mx-2 border-2 border-gray-300 px-10 py-2">
            <span className="font-bold text-xl ">Personal Details:</span>
            {userDetails.map((i) => {
              return (
                <div className="flex flex-col mt-3  ">
                  <span className="mb-3">
                    <strong>Name:</strong> {i.name}
                  </span>
                  <span className="mb-3">
                    <strong>Email id:</strong> {i.email}
                  </span>
                  <span className="mb-3">
                    <strong>Phone:</strong> {i.phone}
                  </span>
                  <span className="mb-3">
                    <strong>Level:</strong> {i.currentLevel}
                  </span>
                </div>
              );
            })}
          </div>

          <div className="card border-2 border-gray-300 p-5">
            <span className="font-bold text-xl">Hospital Details:</span>
            {userDetails.map((i) => {
              return (
                <div className="flex flex-col  mt-3">
                  <span className="mb-3">
                    <strong>Name: </strong> {i.hospitalName}
                  </span>
                  <span className="mb-3">
                    <strong>Address: </strong> {i.hospitalAddress1}
                  </span>
                  <span className="mb-3">
                    <strong>Address: </strong> {i.hospitalAddress2}
                  </span>
                  <span className="mb-3">
                    <strong>Phone: </strong> {i.hospitalPhone}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div>
          <div className="grid grid-cols-12">
            <div className="col-span-4 p-5 bg-red-100">
              <div className="font-bold">Upload Bill and Data:</div>
              <div>
                <input type="file" onChange={handleBillDataFile}></input>
              </div>

              {/* <div className="my-2">
                <label className="">Hospital Id:</label>
                <input
                  placeholder="Hospital ID"
                  className="p-2 py-2 mx-1 rounded-xl"
                  type="text"
                  value={uploadData.hospitalId}
                  onChange={(e) =>
                    setUploadData({
                      ...uploadData,
                      hospitalId: e.target.value,
                    })
                  }
                />
              </div> */}
              <div className="my-2">
                <label>Bill Amount</label>
                <input
                  placeholder="Bill Amount"
                  className="py-2 p-2 mx-1 rounded-xl"
                  type="number"
                  value={uploadData.billAmount}
                  onChange={(e) =>
                    setUploadData({
                      ...uploadData,
                      billAmount: e.target.value,
                    })
                  }
                />
              </div>
              <div className="my-2">
                <label className="mx-1">Multiplier:</label>
                {/* <input
                  placeholder="Point Multiplier"
                  className="py-2 p-2 mx-1 rounded-xl"
                  type="number"
                  value={uploadData.pointMultiplier}
                  onChange={(e) =>
                    setUploadData({
                      ...uploadData,
                      pointMultiplier: e.target.value,
                    })
                  }
                /> */}
                <select
                  onChange={(e) =>
                    setUploadData({
                      ...uploadData,
                      pointMultiplier: e.target.value,
                    })
                  }
                >
                  <option value="1">1</option>
                  <option value="1.25">1.25</option>
                  <option value="1.5">1.5</option>
                  <option value="2">2</option>
                </select>
              </div>

              <button
                className="px-4 py-2 border rounded-xl my-2 bg-blue-600 text-white"
                onClick={handleuploadBillsAndAddDataSubmit}
              >
                submit
              </button>
            </div>
          </div>
          <div className="mt-8">
            <strong>Past Orders</strong>
            <table>
              <thead>
                <tr>
                  <th>Bill Amount</th>
                  <th>Date</th>
                  <th>Multiplier</th>
                  <th>points earned</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {myOrders.map((item, index) => (
                  <tr key={index}>
                    <td>{item.billAmount}</td>
                    <td>{item.date}</td>
                    <td>{item.multipiler}</td>
                    <td>{item.pointsEarned}</td>
                    <td>{item.billPaid ? "Paid" : item.status}</td>
                    <td>
                      {!item.billPaid && (
                        <button
                          className="px-4 py-2 border rounded-xl my-2 bg-blue-600 text-white"
                          onClick={() => updateUserBillPaidStatus(item.orderId)}
                        >
                          Payment done
                        </button>
                      )}
                      <button
                        onClick={() => handleDeleteOrder(item.orderId)}
                        className="px-4 py-2 border rounded-xl my-2 bg-red-600 text-white "
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Pagination for third table */}
            {/* {renderPagination(
            scheduleMeetingData.length,
            scheduleItemsPerPage,
            schedulePaginate,
            scheduleCurrentPage
          )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OneUserView;
