
  

  // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// import { getFirestore } from "firebase/firestore";
// import 'firebase/database';
import { getDatabase } from "firebase/database";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBtZTCdRC6H-OmUCzgC9fGDmaT3TabviWU",
  authDomain: "lifin-aidlife.firebaseapp.com",
  databaseURL: "https://lifin-aidlife-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "lifin-aidlife",
  storageBucket: "lifin-aidlife.appspot.com",
  messagingSenderId: "103452695740",
  appId: "1:103452695740:web:68fdb544add76abb3f44b9",
  measurementId: "G-3D4H4CQ41B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth();

export default app;

// export const db = getFirestore(app);
// export const database = firebase.database();
export const database = getDatabase(app);






