import React from "react";
// import "./Loader.css"; // Import the CSS file
import { RotateLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader-overlay">
      <div className="loader">
        <RotateLoader color="#9e58df" />
      </div>
    </div>
  );
};

export default Loader;
