import { ref, set, get, child, onValue, update, push, remove, getDatabase, runTransaction, query, orderByChild, endAt } from "firebase/database";
import { auth, database } from "../config/firebase_config";
import { onAuthStateChanged } from "firebase/auth";


export async function createDataInFirebaseRTDB(path, data) {

    if (!path) {
        throw new Error('Path must be provided.');
    }
    if (!data) {
        throw new Error('Data must be provided.');
    }
    if (typeof data !== 'object') {
        throw new Error('Data should be an object.');
    }

    console.log("userId", path, "name", "email", data);
    //   const db = getDatabase();
    await set(ref(database, path), data).then(() => {
        // Data saved successfully!
        console.log("Data saved successfully!");
    })
        .catch((error) => {
            // The write failed...
            console.log("The write failed...");
        });

}

onAuthStateChanged(auth, (user) => {
    if (user) {
        console.log("User is authenticated:", user);
        // Check custom claims if needed
        user.getIdTokenResult().then((idTokenResult) => {
            console.log("User claims:", idTokenResult.claims);
        });
    } else {
        console.log("No user is authenticated");
    }
});

export async function getData(path) {
    if (!path) {
        throw new Error('Path must be provided.');
    }

    const databaseRef = ref(database);
    try {
        const snapshot = await get(child(databaseRef, path));
        if (snapshot.exists()) {
            console.log("snapshot.val():", snapshot.val());
            return snapshot.val();
        } else {
            console.log("No data available");
            return "No data available";
        }
    } catch (error) {
        console.error('An error occurred:',path, " ", error.message);
        return `An error occurred: ${error.message}`;
    }
}

//function to read data and return in an ordered manner
export async function readDataInOrder(path, orderBy) {
    if (!path) {
        throw new Error('Path must be provided.');
    }
    if (!orderBy) {
        throw new Error('orderBy must be provided.');
    }

    const databaseRef = ref(database, path);
    try {
        const orderedQuery = query(databaseRef, orderByChild(orderBy));

        const snapshot = await get(orderedQuery);
        if (snapshot.exists()) {
            console.log("snapshot.val():", snapshot.val());
            return snapshot;
        } else {
            console.log("No data available");
            return "No data available";
        }
    } catch (error) {
        console.error('An error occurred:',path, " ", error.message);
        return `An error occurred: ${error.message}`;
    }
}


export async function listenToChanges(path) {
    if (!path) {
        throw new Error('Path must be provided.');
    }
    const databaseRef = ref(database, path);
    onValue(databaseRef, (snapshot) => {
        const data = snapshot.val();
        console.log("data");
        console.log(data);

    });
}

export async function readDataOnceFromLocalCache(path) {
    if (!path) {
        throw new Error('Path must be provided.');
    }
    return onValue(ref(database, path), (snapshot) => {
        const username = (snapshot.val() && snapshot.val().username) || 'Anonymous';
        // ...
    }, {
        onlyOnce: true
    });
}

export async function removeUserData(path) {
    if (!path) {
        throw new Error('Path must be provided.');
    }
    const databaseRef = ref(database);
    remove(child(databaseRef, path), null).then(() => {
        // Data saved successfully!
    })
        .catch((error) => {
            // The write failed...
        });;
}

// export async function updateMultipleNodesAtOnce(uid, username, picture, title, body) {


//     // A post entry.
//     const postData = {
//         author: username,
//         uid: uid,
//         body: body,
//         title: title,
//         starCount: 0,
//         authorPic: picture
//     };

//     // Get a key for a new Post.
//     const newPostKey = push(child(ref(database), 'posts')).key;

//     // Write the new post's data simultaneously in the posts list and the user's post list.
//     const updates = {};
//     updates['/posts/' + newPostKey] = postData;
//     updates['/user-posts/' + uid + '/' + newPostKey] = postData;

//     const databaseRef = ref(database);

//     return update(databaseRef, updates);
// }

export async function updateSingleNode(path, data) {

    if (!path) {
        throw new Error('Path must be provided.');
    }
    if (!data) {
        throw new Error('Data must be provided.');
    }
    if (typeof data !== 'object') {
        throw new Error('Data should be an object.');
    }

    const databaseRef = ref(database);
    return update(child(databaseRef, path), data);
}



export function generateNewKey() {
    // const db = getDatabase();
    const newRewardRef = push(ref(database));

    console.log("newRewardRef.key", newRewardRef.key);
    return newRewardRef.key;
}


//---------------------------------------------------------------



// export async function callATransaction(path) {

//     // const path = '/userPoints/dfsf';

//     transactionUniversal(path, (user) => updateUserPoints(user));

// }


export async function transactionUniversal(path, transactionUpdate) {
    if (typeof transactionUpdate !== 'function') {
        throw new Error('transactionUpdate must be a function');
    }

    const postRef = ref(database, path);

    await runTransaction(postRef, transactionUpdate).then((result) => {
        console.log('Transaction completed successfully:', result);
    }).catch((error) => {
        console.error('Transaction failed:',path, " ", error);
    });
}

// import { getDatabase, ref, runTransaction } from "firebase/database";

function toggleStar(post, uid) {
    if (post) {
        if (post.stars && post.stars[uid]) {
            post.starCount--;
            post.stars[uid] = null;
        } else {
            post.starCount++;
            if (!post.stars) {
                post.stars = {};
            }
            post.stars[uid] = true;
        }
    }
    return post;
}

// export async function updatePostWithTransaction(postId, uid, transactionUpdate) {
//     const db = getDatabase();
//     const postRef = ref(db, `posts/${postId}`);

//     try {
//         await runTransaction(postRef, transactionUpdate);
//         console.log('Post updated in the RTDB');
//         return 'Post updated in the RTDB';
//     } catch (error) {
//         console.error('An error occurred:', error.message);
//         return `An error occurred: ${error.message}`;
//     }
// }

//function to read data with a where value is less than or less than and return in an ascending manner
export async function readDataWithWhereValueIsLessThan(path, value, orderBy) {
    if (!path) {
        throw new Error("Path must be provided.");
    }
    if (!orderBy) {
        throw new Error("orderBy must be provided.");
    }
    if (!value) {
        throw new Error("where must be provided.");
    }

    const databaseRef = ref(database, path);
    try {
        const orderedQuery = query(databaseRef, orderByChild(orderBy), endAt(value));

        const snapshot = await get(orderedQuery);
        if (snapshot.exists()) {
            console.log("snapshot.val():", snapshot.val());
            return snapshot.val();
        } else {
            console.log("No data available");
            return "No data available";
        }
    } catch (error) {
        console.error("An error occurred:",path, " ", error.message);
        return `An error occurred: ${error.message}`;
    }
}

// Usage
// updatePostWithTransaction('post1', 'user1', (post) => toggleStar(post, 'user1'))
//     .then((message) => console.log(message))
//     .catch((error) => console.error('Error:', error));