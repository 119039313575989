import { set } from "firebase/database";
import React, { useState } from "react";
import { signupNewUserAdmin } from "../firebase/firebase_admin";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import Loader from "./Loader";

const Create = () => {
  const [details, setDetails] = useState({
    personalName: "",
    personalEmail: "",
    personalPhone: "",
    personalImg: null,
    hospitalName: "",
    hospitalAddress: "",
    hospitalPhone: "",
    password: "",
    hospitalDoc: null,
    hospitalAddress2: "",
  });
  const [loading, setLoading] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordShow = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFileChange = (e) => {
    let file = e.target.files[0]; // Get the first selected file
    setDetails({
      ...details,
      personalImg: file, // Store the File object in profileImg
    });
  };

  const handleHospitalDoc = (e) => {
    let file = e.target.files[0];
    setDetails({
      ...details,
      hospitalDoc: file,
    });
  };
  console.log(details);

  // ***api

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     console.log("sign up calling ");
  //     let newUser = await signupNewUserAdmin(
  //       details.personalImg,
  //       details.personalEmail,
  //       details.password,
  //       details.personalName,
  //       details.personalPhone,
  //       details.hospitalName,
  //       details.hospitalAddress,
  //       details.hospitalAddress2,
  //       details.hospitalPhone,
  //       details.hospitalDoc
  //     );
  //     toast.success("User Created");
  //     setDetails({
  //       personalName: "",
  //       personalEmail: "",
  //       personalPhone: "",
  //       personalImg: null,
  //       hospitalName: "",
  //       hospitalAddress: "",
  //       hospitalPhone: "",
  //       password: "",
  //       hospitalDoc: null,
  //       hospitalAddress2: "",
  //     });
  //     // console.log("New User:", newUser);
  //   } catch (error) {
  //     // const err = JSON.parse(error);
  //     console.log(error.message);
  //     toast.warn(error.message);
  //     // toast.warn(error);
  //     // console.log(error);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      // Check if the personal image is of an accepted image type
      if (
        details.personalImg &&
        !details.personalImg.type.startsWith("image/")
      ) {
        toast.warn("Please upload a valid image file.");
        return; // Exit the function if the file is not an image
      }

      console.log("sign up calling ");
      let newUser = await signupNewUserAdmin(
        details.personalImg,
        details.personalEmail,
        details.password,
        details.personalName,
        details.personalPhone,
        details.hospitalName,
        details.hospitalAddress,
        details.hospitalAddress2,
        details.hospitalPhone,
        details.hospitalDoc
      );
      toast.success("User Created");
      setDetails({
        personalName: "",
        personalEmail: "",
        personalPhone: "",
        personalImg: null,
        hospitalName: "",
        hospitalAddress: "",
        hospitalPhone: "",
        password: "",
        hospitalDoc: null,
        hospitalAddress2: "",
      });
      // console.log("New User:", newUser);

      setLoading(false);
    } catch (error) {
      console.log(error.message);

      setLoading(false);
      toast.warn(error.message);
    }
  };

  // **
  return (
    <div className="container grid p-16  grid-cols-12 bg-gray-300">
      <div className="col-span-6 p-10">
        <div className="my-2">
          {loading ? <Loader /> : null}
          <label className="font-sans font-medium my-2">Name</label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              value={details.personalName}
              type="text"
              placeholder="Name"
              onChange={(e) =>
                setDetails({
                  ...details,
                  personalName: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="my-2">
          <label className="font-sans font-medium my-2">Email</label>
          <div>
            <input
              className="py-2 font-sans  rounded-md px-10 outline-none"
              type="email"
              placeholder="Email"
              value={details.personalEmail}
              onChange={(e) =>
                setDetails({
                  ...details,
                  personalEmail: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="my-2">
          <label className="font-sans font-medium my-2">Password</label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              value={details.password}
              onChange={(e) =>
                setDetails({
                  ...details,
                  password: e.target.value,
                })
              }
            />
            <button onClick={handlePasswordShow}>click</button>
          </div>
        </div>
        <div className="my-2">
          <label className="font-sans font-medium my-2">Phone Number</label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              value={details.personalPhone}
              type="number"
              placeholder="Phone"
              onChange={(e) =>
                setDetails({
                  ...details,
                  personalPhone: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="my-2">
          <label className="font-sans font-medium my-2">Profile Img</label>
          <input
            className="py-2 font-sans rounded-md px-10 outline-none"
            type="file"
            id="file-input"
            name="ImageStyle"
            // value={details.personalImg}
            onChange={handleFileChange}
          />
        </div>
      </div>
      <div className="col-span-6 flex flex-col p-10 ">
        <div className="my-2">
          <label className="font-sans font-medium my-2">Hospital Name</label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              value={details.hospitalName}
              type="text"
              placeholder="Hospital Name"
              onChange={(e) =>
                setDetails({
                  ...details,
                  hospitalName: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="my-2">
          <label className="font-sans font-medium my-2">Hospital Address</label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              type="text"
              placeholder="Address"
              value={details.hospitalAddress}
              onChange={(e) =>
                setDetails({
                  ...details,
                  hospitalAddress: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="my-2">
          <label className="font-sans font-medium my-2">
            Hospital Address 2
          </label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              type="text"
              placeholder="Address"
              value={details.hospitalAddress2}
              onChange={(e) =>
                setDetails({
                  ...details,
                  hospitalAddress2: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="my-2">
          <label className="font-sans font-medium my-2">Hospital Phone</label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              type="number"
              placeholder="Hospital Phone"
              value={details.hospitalPhone}
              onChange={(e) =>
                setDetails({
                  ...details,
                  hospitalPhone: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="my-2">
          <label className="font-sans font-medium my-2">
            Hospital Document
          </label>
          <div>
            <input
              className="py-2 font-sans rounded-md px-10 outline-none"
              type="file"
              placeholder="Document"
              // value={details.hospitalDoc}
              onChange={handleHospitalDoc}
            />
            {details.hospitalDoc && (
              <p>Selected file: {details.hospitalDoc.name}</p>
            )}
          </div>
        </div>
        <div className="flex items-end justify-center my-3">
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="px-5 py-2 bg-purple-800 rounded-lg text-white"
            onClick={handleSubmit}
          >
            <span className="font-sans font-normal">Submit</span>
          </motion.button>
        </div>
      </div>
    </div>
  );
};

export default Create;
