import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { auth } from '../config/firebase_config';
// import { UserModel } from './models/user_model';
import { signup_error, login_error } from '../utils/error_map';
import { UserModel } from '../models/user_model';
// import database from "firebase/database";
// import { createDataInFirebaseRTDB } from './rtdb_operations';



export async function signupUserFirebaseAuth(personalEmail, password) {

  if (!personalEmail || !password ) {
    return "Please provide all the details";
  }

  try {
    const userCredential = await createUserWithEmailAndPassword(auth, personalEmail, password);
    console.log("userCredentialinAUTH", userCredential);
    console.log("userCredentialinAUTH user", userCredential.user.uid);
   return new UserModel(userCredential)
    // return userCredential;

  } catch (error) {
    console.log("error in signupUserFirebase" + signup_error[error.code] ?? error);
    console.log("error.code " + error.code + " " + typeof (error.code));

    const errorCode = error.code;
    // return signup_error[errorCode] ?? error;
    throw new Error(signup_error[errorCode] ?? error);
  }
}
//qu34DexnSGfxL0NhNPLiueMGuYy2


export async function loginUserFirebaseAuth(email, password) {

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    // console.log(userCredential);

    // return new UserModel(userCredential.user.uid, userCredential.user.email, userCredential.user.accessToken);
    return userCredential;

  } catch (error) {
    // return login_error[error.code] ?? error;
    const errorCode = error.code;
    throw new Error(login_error[errorCode] ?? error);
  }
}


export async function logoutUserFirebaseAuth() {
  try {
    await signOut(auth);

  } catch (error) {
    console.error(error);
    throw new Error("Error in logging out");
  }
}