import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Upload from "./Pages/Upload";
import Create from "./Pages/Create";
import MainUser from "./Pages/MainUser";
import OneUserView from "./Pages/OneUserView";
import User from "./Pages/User";
import SchemesAdmin from "./Pages/SchemesAdmin";
import Login from "./Pages/Login";
// import ProtectedRoute from "./ProtectedRoute"; // Import the protected route component
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Pages/ProtectedRoute";

const App = () => {
  const [userDetails, setuserDetails] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Track authentication status

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);
  const handleClick = (i) => {
    setuserDetails([i]);
  };
  // console.log(userDetails);

  return (
    <div className="flex">
      <ToastContainer />
      <BrowserRouter>
        <User />
        <Routes>
          {/* Public route for login */}
          <Route
            path="/"
            element={<Login setIsAuthenticated={setIsAuthenticated} />}
          />

          {/* Protected routes */}
          <Route
            path="/home"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <MainUser handleClick={handleClick} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Upload"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Upload />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Oneuserview"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <OneUserView userDetails={userDetails} />
              </ProtectedRoute>
            }
          />
          <Route
            path="/Create"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Create />
              </ProtectedRoute>
            }
          />
          <Route
            path="/SchemesAdmin"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <SchemesAdmin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
